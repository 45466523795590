import qs from 'qs'
import {useEffect, useState} from 'react'
import {postDionysus, getDionysus, putDionysus} from 'services/dionysus'
import {PageOrder, PaginatedResponse} from '@pleo-io/deimos'
import useSWR from 'swr'

import {
    CreateDemoAccountRequest,
    DemoAccountModel,
    SortableDemoAccountProperty,
    toDemoAccountsSorting,
    DemoAccountResponse,
    EmployeeResponse,
    GetPrecreatedDemoAccountRequest
} from 'types/demo-accounts'
import {useCursorPagination} from 'utils/use-cursor-pagination'

const BASE_URL = 'rest/v1/demo-accounts'

export const createDemoAccount = async (
    createDemoAccountRequest: CreateDemoAccountRequest
): Promise<DemoAccountResponse> =>
    await postDionysus(BASE_URL, createDemoAccountRequest).then((res) => res.json())

export const getPreCreatedDemoAccount = async (
    createDemoAccountRequest: GetPrecreatedDemoAccountRequest
): Promise<DemoAccountResponse> =>
    await postDionysus(`${BASE_URL}/pre-created`, createDemoAccountRequest).then((res) =>
        res.json()
    )

const getAdmin = (employees: EmployeeResponse[]) => employees.find(({role}) => role === 'OWNER')

export const useCreateDemoAccount = () => {
    const [createDemoAccountRequest, setCreateDemoAccountRequest] =
        useState<CreateDemoAccountRequest | null>(null)
    const [createPreCreatedDemoAccountRequest, setCreatePreCreatedDemoAccountRequest] =
        useState<GetPrecreatedDemoAccountRequest | null>(null)
    const [accountDetails, setAccountDetails] = useState<EmployeeResponse | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        if (createDemoAccountRequest) {
            setLoading(true)
            createDemoAccount(createDemoAccountRequest)
                .then((res) => {
                    const adminDetails = getAdmin(res.employees)
                    if (adminDetails) {
                        setAccountDetails(adminDetails)
                    } else {
                        throw new Error('No admin')
                    }
                })
                .catch(setError)
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [createDemoAccountRequest])

    useEffect(() => {
        if (createPreCreatedDemoAccountRequest) {
            setLoading(true)
            getPreCreatedDemoAccount(createPreCreatedDemoAccountRequest)
                .then((res) => {
                    const adminDetails = getAdmin(res.employees)
                    if (adminDetails) {
                        setAccountDetails(adminDetails)
                    } else {
                        throw new Error('No admin')
                    }
                })
                .catch((err) => setError(err))
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [createPreCreatedDemoAccountRequest])

    const dismissError = () => setError(null)

    return {
        accountDetails,
        loading,
        error,
        setCreateDemoAccountRequest,
        setCreatePreCreatedDemoAccountRequest,
        dismissError
    }
}

export const defaultLimit = 10
export const defaultSortedProperty: SortableDemoAccountProperty = 'CREATED_AT'
export const defaultPageOrder: PageOrder = PageOrder.DESC

export const useDemoAccounts = (requesterEmail?: string) => {
    const {paginationRequest, setCurrentPagination, ...paginationMutations} =
        useCursorPagination<SortableDemoAccountProperty>({
            sorting: toDemoAccountsSorting(defaultSortedProperty, defaultPageOrder),
            limit: defaultLimit
        })

    const response = useSWR<PaginatedResponse<DemoAccountModel, SortableDemoAccountProperty>>(
        requesterEmail
            ? `${BASE_URL}?${qs.stringify({
                  ...paginationRequest,
                  requesterEmail,
                  sorting: JSON.stringify(paginationRequest.sorting)
              })}`
            : null,
        getDionysus
    )

    const pagination = response.data?.pagination

    useEffect(() => {
        if (pagination) {
            setCurrentPagination(pagination)
        }
    }, [pagination, setCurrentPagination])

    const updateExpiresAt = async (id: string, expiresAt: string | null) => {
        await putDionysus(`${BASE_URL}/expired/${id}`, {expiresAt})
        response.mutate()
    }

    return {
        ...response,
        ...paginationMutations,
        mutations: {updateExpiresAt},
        data: response.data?.data,
        pagination
    }
}

export const useDemoAccount = (demoAccountId: string | null) =>
    useSWR<DemoAccountResponse, Error>(
        demoAccountId ? `${BASE_URL}/${demoAccountId}` : null,
        getDionysus
    )
