type RequestUrl = string
type HTTPMethod = 'get' | 'put' | 'post' | 'patch' | 'delete'

const methods = {
    get: {},
    put: {},
    post: {},
    patch: {},
    delete: {}
}

let mockResponsesByMethod: Record<HTTPMethod, Record<RequestUrl, Response[] | undefined>> = {
    ...methods
}

export const getExistingInterceptors = (method: HTTPMethod, url: string): Response[] | undefined =>
    mockResponsesByMethod[method]?.[url]

const createRequestHandler = (method: HTTPMethod) => (url: string, response: Response) => {
    const existingInterceptors = getExistingInterceptors(method, url)
    if (existingInterceptors) {
        existingInterceptors.push(response)
    } else {
        mockResponsesByMethod[method][url] = [response]
    }
}

export const interceptRequest = {
    get: createRequestHandler('get'),
    put: createRequestHandler('put'),
    post: createRequestHandler('post'),
    patch: createRequestHandler('patch'),
    delete: createRequestHandler('delete')
}

export const clearInterceptors = () => (mockResponsesByMethod = {...methods})
export const normalizeHTTPMethod = (method: string) => method.toLowerCase() as HTTPMethod
