import {
    getPendingOrganizationRequestAction,
    OrganizationGroupingRequest,
    useOrganizationById
} from 'services/organizations'

import {useParams} from 'react-router-dom'
import {useCompany} from 'services/company'

export const pleoIdPattern = /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/

export interface FormType {
    mainContact: string
    existingEntites: {label: string; value: string}[]
    companyIdsToAdd: {companyIdToAdd: {value: string; label: string}}[]
    organization: {label: string; value: string}
    newOrganizationName?: string
    registryIdsToCreate?: {registryIdToCreate: {value: string; label: string}}[]
    type: 'UPDATE' | 'CREATE' | 'DELETE'
}

export interface ConfirmationModalDataType {
    organization?: {
        label: string
        value: string
    }
    newOrganizationName?: string
    companiesToRemove?: {label: string; value: string}[]
    companiesToAdd?: {label: string; value: string}[]
    registryIdsToCreate?: {label: string; value: string}[]
    mainContact: string
    type: 'CREATE' | 'UPDATE' | 'DELETE'
}

export const {email} = {
    email: window.sessionStorage.getItem('email') || ''
}

export const getConfirmationModalTitle = (values: ConfirmationModalDataType) => {
    let title
    switch (values.type) {
        case 'UPDATE':
            title = `You are UPDATING the exisiting organization: ${values.organization?.label}`
            break
        case 'CREATE':
            title = `You are CREATING the organization: ${values.newOrganizationName}`
            break
        case 'DELETE':
            title = `You are DELETING the exisiting organization: ${values.organization?.label}`
            break
        default:
            title = 'Confirm organization details'
            break
    }
    return title
}

export const getCompaniesToRemove = (
    values: FormType,
    entites?: {
        label: string
        value: string
    }[]
) => {
    if (!entites || !values.existingEntites) return

    const result = values.existingEntites.map((entity) => entity.value)
    const difference: {label: string; value: string}[] = entites.filter(
        (x) => x.value && !result.includes(x.value)
    )
    return difference ?? []
}

export const getConfirmationModalData = ({
    values,
    entities,
    selectedOrganization
}: {
    values: FormType
    entities?: {
        label: string
        value: string
    }[]
    selectedOrganization?: {
        label: string
        value: string
    }
}) => {
    const companiesToRemove = getCompaniesToRemove(values, entities)

    const groupingRequestData: OrganizationGroupingRequest = {
        organizationName: values.newOrganizationName?.trim() || selectedOrganization?.label.trim(),
        organizationId: selectedOrganization?.value,
        mainContact: email,
        companyIdsToAdd:
            values.companyIdsToAdd.length !== 0 && values.companyIdsToAdd[0].companyIdToAdd
                ? values.companyIdsToAdd.map((id) => id.companyIdToAdd.value)
                : undefined,
        companyIdsToRemove: companiesToRemove && companiesToRemove.map((company) => company.value),
        registryIdsToCreate:
            values.registryIdsToCreate?.length !== 0 &&
            values.registryIdsToCreate &&
            values.registryIdsToCreate[0].registryIdToCreate
                ? values.registryIdsToCreate?.map((id) => id.registryIdToCreate.value)
                : undefined,
        type: values.newOrganizationName ? 'CREATE' : 'UPDATE'
    }

    const confirmationModalData: ConfirmationModalDataType = {
        organization: selectedOrganization,
        newOrganizationName: values.newOrganizationName,
        companiesToRemove: companiesToRemove,
        companiesToAdd:
            values.companyIdsToAdd.length !== 0 && values.companyIdsToAdd[0].companyIdToAdd
                ? values.companyIdsToAdd.map((id) => {
                      return {value: id.companyIdToAdd.value, label: id.companyIdToAdd.label[0]}
                  })
                : undefined,
        registryIdsToCreate:
            values.registryIdsToCreate?.length !== 0 &&
            values.registryIdsToCreate &&
            values.registryIdsToCreate[0].registryIdToCreate
                ? values.registryIdsToCreate?.map((id) => {
                      return {
                          value: id.registryIdToCreate.value,
                          label: id.registryIdToCreate.label[0]
                      }
                  })
                : undefined,
        mainContact: email,
        type: values.newOrganizationName ? 'CREATE' : 'UPDATE'
    }

    return {confirmationModalData, groupingRequestData}
}

export const useCompanyGroupingData = ({organizationId}: {organizationId?: string} = {}) => {
    const {id: companyId} = useParams<{id: string}>()
    const {data: dataCompanyResponse, error: errorCompanyResponse} = useCompany(companyId)
    const {
        data: dataOrganizationResponse,
        error: errorOrganizationResponse,
        mutation
    } = useOrganizationById(organizationId ?? dataCompanyResponse?.organizationId)

    const isLoadingCompanyData = !dataCompanyResponse && !errorCompanyResponse
    const isLoadingOrganizationData = !dataOrganizationResponse && !errorOrganizationResponse
    const hasRelatedOrganization = (dataOrganizationResponse?.companies?.length ?? 0) > 0
    const isLoadingInitialData = isLoadingCompanyData && isLoadingOrganizationData

    return {
        isLoadingCompanyData,
        isLoadingOrganizationData,
        hasRelatedOrganization,
        isLoadingInitialData,
        company: dataCompanyResponse,
        organization: dataOrganizationResponse,
        mutation
    }
}

export const hasPendingOrganizationRequestAction = async ({
    companyId,
    registryId
}: {
    companyId?: string
    registryId?: string
}) => {
    if (companyId) {
        const pendingActionForExistingCompany = await getPendingOrganizationRequestAction({
            companyId
        })
        return pendingActionForExistingCompany?.data?.[0]?.companyId === companyId
    } else if (registryId) {
        const pendingActionForNewCompany = await getPendingOrganizationRequestAction({registryId})
        return pendingActionForNewCompany?.data?.[0]?.registryId === registryId
    } else {
        return false
    }
}
