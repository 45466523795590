import styled from 'styled-components'

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    margin-top: 20px;
`
export const NearbyButtonContainer = styled.div`
    display: flex;
    justify-content: left;
    margin-top: 20px;
`
