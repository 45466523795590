import React from 'react'
import {Tooltip, TreeSelect, TreeSelectProps, Typography} from 'antd'
import {TCountryCode, getEmojiFlag} from 'countries-list'
import {OrganizationEntity, useOrganizationById} from 'services/organizations'

export const RelatedByOrganization = ({
    organizationId,
    treeSelectProps
}: {
    selectable?: boolean
    treeSelectProps?: TreeSelectProps
    organizationId?: string
    entityId?: string
    onChangeEntity?: (entityId: string) => void
}) => {
    const {data, error} = useOrganizationById(organizationId)

    if (!data && !error) {
        return null
    }

    return (
        <TreeSelect
            style={{
                width: '100%',
                padding: 10
            }}
            treeDefaultExpandAll
            treeIcon={false}
            defaultOpen
            treeLine={{showLeafIcon: false}}
            showSearch={false}
            {...treeSelectProps}
            treeData={[
                {
                    title: data?.name,
                    key: '0-0',
                    children: data?.companies.map((cmp: OrganizationEntity, index: number) => ({
                        title: (
                            <Tooltip
                                color="#ececec"
                                arrowPointAtCenter
                                title={
                                    <Typography.Paragraph>
                                        Company ID:{' '}
                                        <Typography.Text copyable>{cmp.companyId}</Typography.Text>
                                    </Typography.Paragraph>
                                }
                            >
                                {getEmojiFlag(cmp.address.country as TCountryCode)} {cmp.name}
                            </Tooltip>
                        ),
                        key: `0-0-${index}`
                    }))
                }
            ]}
        />
    )
}
