import React from 'react'
import {Button, Select} from 'antd'
import styled from 'styled-components'
import {PaginationData} from '@pleo-io/deimos'

interface CursorPaginationControlsProps<SortableProperties extends string> {
    pagination: PaginationData<SortableProperties>
    isFetching: boolean
    limit: number
    onChangeLimit: (newLimit: number) => void
    onPrevPage: () => void
    onNextPage: () => void
}

function CursorPaginationControls<SortableProperties extends string>({
    onPrevPage,
    onNextPage,
    pagination,
    isFetching,
    limit,
    onChangeLimit
}: CursorPaginationControlsProps<SortableProperties>) {
    return (
        <Wrapper>
            <Button
                onClick={onPrevPage}
                disabled={!pagination.hasPreviousPage || isFetching}
                data-testid="prev-page"
            >
                Previous
            </Button>
            <Button
                onClick={onNextPage}
                disabled={!pagination.hasNextPage || isFetching}
                data-testid="next-page"
            >
                Next
            </Button>
            <Select value={limit} onChange={onChangeLimit} style={{width: 150}}>
                <Select.Option value={10}>10 per page</Select.Option>
                <Select.Option value={30}>30 per page</Select.Option>
                <Select.Option value={50}>50 per page</Select.Option>
                <Select.Option value={100}>100 per page</Select.Option>
            </Select>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`

export default CursorPaginationControls
