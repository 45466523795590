import {
    Card,
    Col,
    Row,
    Skeleton,
    Space,
    Statistic,
    Table,
    Tabs,
    Tag,
    Tooltip,
    Typography
} from 'antd'
import {ColumnProps} from 'antd/lib/table'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import React from 'react'
import {
    OrganizationRequest,
    usePendingOrganizationRequestActions,
    usePendingOrganizationRequestActionsCount,
    useProcessedOrganizationRequestActions,
    useProcessedOrganizationRequestActionsCount
} from 'services/organizations'
import {email as requesterEmail} from './utils'
import moment from 'moment'

const getColorFromStatus = (status: 'PENDING' | 'COMPLETED' | 'DECLINED'): string => {
    switch (status) {
        case 'PENDING':
            return 'processing'
        case 'COMPLETED':
            return 'success'
        case 'DECLINED':
            return 'error'
        default:
            return 'warning'
    }
}

const getColorFromType = (type: 'NEW' | 'ADD' | 'REMOVE'): string => {
    switch (type) {
        case 'NEW':
            return 'warning'
        case 'ADD':
            return 'processing'
        case 'REMOVE':
            return 'error'
        default:
            return 'warning'
    }
}

export const PendingMultiEntityRequests = ({
    companyId,
    requesterEmail
}: {
    companyId?: string
    requesterEmail?: string
}) => {
    const [fetchLimit, setFetchLimit] = React.useState<number | undefined>()
    const {
        data,
        isLoadingInitialData,
        pagination,
        fetchNextPage,
        fetchPreviousPage,
        isLoadingMore
    } = usePendingOrganizationRequestActions({limit: fetchLimit, requesterEmail, companyId})

    const columns: ColumnProps<OrganizationRequest>[] = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: (_name, record) => (
                <Tag color={getColorFromType(record.type)}>{record.type}</Tag>
            )
        },
        {
            title: 'Org Name',
            dataIndex: 'organizationName',
            render: (name, record) => (
                <Typography.Text copyable={{text: name}}>{record.organizationName}</Typography.Text>
            )
        },
        {
            title: 'Org ID',
            dataIndex: 'organizationId',
            render: (name, record: OrganizationRequest) =>
                record.organizationId ? (
                    <Space direction="vertical" align="start">
                        <Typography.Text copyable={{text: name}}>
                            {record.organizationId}
                        </Typography.Text>
                    </Space>
                ) : undefined
        },
        {
            title: 'Company ID',
            dataIndex: 'companyId',
            render: (name, record: OrganizationRequest) =>
                record.companyId ? (
                    <Space direction="vertical" align="start">
                        <Typography.Text copyable={{text: name}}>
                            {record.companyId}
                        </Typography.Text>
                    </Space>
                ) : undefined
        },
        {
            title: 'Registry ID',
            dataIndex: 'registryId',
            render: (name, record: OrganizationRequest) =>
                record.registryId ? (
                    <Space direction="vertical" align="start">
                        <Typography.Text copyable={{text: name}}>
                            {record.registryId}
                        </Typography.Text>
                    </Space>
                ) : undefined
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (_name, record: OrganizationRequest) => moment(record.createdAt).format('lll')
        }
    ]

    return (
        <Card>
            <Table
                data-testid="table"
                dataSource={data}
                columns={columns}
                loading={isLoadingInitialData || isLoadingMore}
                rowKey="id"
                pagination={false}
            />
            <CursorPaginationControls
                pagination={{
                    hasPreviousPage: !!pagination?.hasPreviousPage,
                    hasNextPage: !!pagination?.hasNextPage,
                    currentRequestPagination: {
                        ...pagination?.currentRequestPagination
                    },
                    startCursor: pagination?.startCursor ?? null,
                    endCursor: pagination?.endCursor ?? null
                }}
                limit={fetchLimit ?? pagination?.currentRequestPagination?.limit ?? 25}
                onChangeLimit={(limit) => setFetchLimit(limit)}
                isFetching={isLoadingMore}
                onNextPage={fetchNextPage}
                onPrevPage={fetchPreviousPage}
            />
        </Card>
    )
}

export const ProcessedMultiEntityRequests = ({
    companyId,
    requesterEmail
}: {
    companyId?: string
    requesterEmail?: string
}) => {
    const [fetchLimit, setFetchLimit] = React.useState<number | undefined>()
    const {
        data,
        isLoadingInitialData,
        pagination,
        fetchNextPage,
        fetchPreviousPage,
        isLoadingMore
    } = useProcessedOrganizationRequestActions({limit: fetchLimit, companyId, requesterEmail})

    const columns: ColumnProps<OrganizationRequest>[] = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: (_name, record) => (
                <Tag color={getColorFromType(record.type)}>{record.type}</Tag>
            )
        },
        {
            title: 'Org Name',
            dataIndex: 'organizationName',
            render: (name, record) => (
                <Typography.Text copyable={{text: name}}>{record.organizationName}</Typography.Text>
            )
        },
        {
            title: 'Org ID',
            dataIndex: 'organizationId',
            render: (name, record: OrganizationRequest) =>
                record.organizationId ? (
                    <Space direction="vertical" align="start">
                        <Typography.Text copyable={{text: name}}>
                            {record.organizationId}
                        </Typography.Text>
                    </Space>
                ) : undefined
        },
        {
            title: 'Company ID',
            dataIndex: 'companyId',
            render: (name, record: OrganizationRequest) =>
                record.companyId ? (
                    <Space direction="vertical" align="start">
                        <Typography.Text copyable={{text: name}}>
                            {record.companyId}
                        </Typography.Text>
                    </Space>
                ) : undefined
        },
        {
            title: 'Registry ID',
            dataIndex: 'registryId',
            render: (name, record: OrganizationRequest) =>
                record.registryId ? (
                    <Space direction="vertical" align="start">
                        <Typography.Text copyable={{text: name}}>
                            {record.registryId}
                        </Typography.Text>
                    </Space>
                ) : undefined
        },
        {
            title: 'Status',
            dataIndex: 'status',
            onCell: (_) => ({onClick: (e) => e.stopPropagation()}),
            render: (_name, record: OrganizationRequest) => (
                <Tag color={getColorFromStatus(record.status)}>{record.status}</Tag>
            )
        },
        {
            title: 'Reviewed by',
            dataIndex: 'reviewerEmail',
            render: (_name, record: OrganizationRequest) =>
                record.reviewerEmail ? (
                    <Tooltip title="This is the email of the Compliance Officer that reviewed this request. You can email them if you disagree or need further clarification.">
                        <Typography.Text copyable>{record.reviewerEmail}</Typography.Text>
                    </Tooltip>
                ) : null
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (_name, record: OrganizationRequest) => moment(record.createdAt).format('lll')
        }
    ]

    return (
        <Card>
            <Table
                data-testid="table"
                dataSource={data}
                columns={columns}
                loading={isLoadingInitialData || isLoadingMore}
                rowKey="id"
                pagination={false}
            />
            <CursorPaginationControls
                pagination={{
                    hasPreviousPage: !!pagination?.hasPreviousPage,
                    hasNextPage: !!pagination?.hasNextPage,
                    currentRequestPagination: {
                        ...pagination?.currentRequestPagination
                    },
                    startCursor: pagination?.startCursor ?? null,
                    endCursor: pagination?.endCursor ?? null
                }}
                limit={fetchLimit ?? pagination?.currentRequestPagination?.limit ?? 25}
                onChangeLimit={(limit) => setFetchLimit(limit)}
                isFetching={isLoadingMore}
                onNextPage={fetchNextPage}
                onPrevPage={fetchPreviousPage}
            />
        </Card>
    )
}

export const CompanyGroupingRequests = () => {
    const organizationTotalPendingRequestCount = usePendingOrganizationRequestActionsCount({
        requesterEmail
    })
    const organizationTotalProcessedRequestCount = useProcessedOrganizationRequestActionsCount({
        requesterEmail
    })

    return (
        <Skeleton loading={false}>
            <Row
                gutter={16}
                style={{
                    padding: '10px 0px',
                    background: '#f0f2f5'
                }}
            >
                <Col span={12}>
                    <Card>
                        <Statistic
                            title="Total pending"
                            value={organizationTotalPendingRequestCount?.data?.total}
                            valueStyle={{color: '#7d7c37b8'}}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Statistic
                            title="Total processed"
                            value={organizationTotalProcessedRequestCount?.data?.total}
                            valueStyle={{color: '#3f8600'}}
                        />
                    </Card>
                </Col>
            </Row>
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        id: '1',
                        key: '1',
                        label: 'Pending',
                        children: <PendingMultiEntityRequests requesterEmail={requesterEmail} />
                    },
                    {
                        id: '2',
                        key: '2',
                        label: 'Processed',
                        children: <ProcessedMultiEntityRequests requesterEmail={requesterEmail} />
                    }
                ]}
            />
        </Skeleton>
    )
}
