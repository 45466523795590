import React, {FC} from 'react'
import {Divider, Button, Typography} from 'antd'
import {Formik} from 'formik'
import {FormItem, Input} from 'formik-antd'

import * as Yup from 'yup'

import Alert from 'components/alert'
import RemoveWhitelist from 'components/remove-whitelist'
import {getHubspotCompanyLink} from 'utils'

import * as s from './whitelist.styles'

const {Text} = Typography

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email').required('Email is required')
})

export interface FormValues {
    email: string
}

interface Props {
    company: string
    companyIdForWhitelistedEmail: string
    onSubmit: (values: FormValues) => void
    removeWhitelist: () => void
}

const WhitelistNew: FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    company,
    companyIdForWhitelistedEmail,
    onSubmit,
    removeWhitelist
}) => {
    const portalId = window.sessionStorage.getItem('portalId') || ''
    const hubspotCompanyLink = getHubspotCompanyLink(portalId, companyIdForWhitelistedEmail)
    return (
        <>
            <s.AlertContainer>
                <Alert type="warning" message={`No email whitelisted for ${company}`} />
                <Alert type="info" message="You can only whitelist one email per company" />
            </s.AlertContainer>
            <Divider />
            <Formik
                onSubmit={onSubmit}
                initialValues={{email: ''}}
                validationSchema={validationSchema}
            >
                {({isValid, values}) => {
                    return (
                        <s.Form layout="vertical">
                            <FormItem
                                name="email"
                                label={<s.BoldLabel>Whitelisted email</s.BoldLabel>}
                                colon={false}
                            >
                                <Input name="email" style={{width: '500px'}} placeholder="Email" />
                            </FormItem>
                            <s.ButtonContainer>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={!values['email'] || !isValid}
                                    data-testid="submit"
                                >
                                    Whitelist email
                                </Button>
                            </s.ButtonContainer>
                            {companyIdForWhitelistedEmail && (
                                <>
                                    <Text>
                                        This email has already been whitelisted for the following{' '}
                                        <a
                                            href={hubspotCompanyLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            HubSpot company
                                        </a>
                                        . Consider merging these two companies in case they are
                                        duplicates or directly removing the whitelist (see below).
                                    </Text>
                                    <Divider />
                                    <RemoveWhitelist
                                        checkBoxMessage="I understand that removing this whitelist will remove the possibility for signing up to Pleo from this email."
                                        removeWhitelist={removeWhitelist}
                                    />
                                </>
                            )}
                        </s.Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default WhitelistNew
