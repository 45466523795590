import {Typography} from 'antd'
import ErrorBoundary from 'providers/error-boundary'
import React, {FC} from 'react'
import {useHasAmlAnswers} from 'services/company'

const {Text} = Typography

const HasAmlAnswers: FC<
    React.PropsWithChildren<React.PropsWithChildren<{deimosCompanyId: string}>>
> = ({deimosCompanyId}) => {
    const {hasAmlAnswers} = useHasAmlAnswers(deimosCompanyId)

    return (
        <Text>
            {hasAmlAnswers === undefined ? 'Failed to retrieve' : hasAmlAnswers ? 'True' : 'False'}
        </Text>
    )
}

const HasAmlAnswersContainer: FC<
    React.PropsWithChildren<React.PropsWithChildren<{deimosCompanyId: string}>>
> = ({deimosCompanyId}) => {
    return (
        <ErrorBoundary renderOnError={<div>Failed to retrieve</div>}>
            <HasAmlAnswers deimosCompanyId={deimosCompanyId} />
        </ErrorBoundary>
    )
}

export default HasAmlAnswersContainer
