import React from 'react'
import {Descriptions, Table, Typography, Tag, Space} from 'antd'
import {ColumnsType} from 'antd/lib/table'
import {PresetColorType} from 'antd/lib/_util/colors'

import config from 'config'

import {useDemoAccount} from 'services/demo-accounts'
import {EmployeeResponse, Role} from 'types/demo-accounts'
import formatCurrency from 'utils/format-currency'

import {countries, getEmojiFlag} from 'countries-list'
import {SupportedCountry} from 'types/countries'
import {ArrowLeftOutlined} from '@ant-design/icons'

const SMS_OTP = config.auth.smsOtp

const {Text} = Typography

interface Props {
    demoAccountId: string
    onGoBack: () => void
}

const getRoleColor = (role: Role): PresetColorType => {
    if (role === 'OWNER') {
        return 'gold'
    } else {
        return 'blue'
    }
}

const getCountryName = (country: SupportedCountry) => {
    const {name} = countries[country]
    const emojiFlag = getEmojiFlag(country)
    return `${name} ${emojiFlag}`
}

const DemoAccount = ({demoAccountId, onGoBack}: Props) => {
    const {data} = useDemoAccount(demoAccountId)

    const columns: ColumnsType<EmployeeResponse> = [
        {
            title: 'Role',
            dataIndex: 'role',
            render: (role: Role) => <Tag color={getRoleColor(role)}>{role}</Tag>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (email: string) => <Text copyable>{email}</Text>
        },
        {
            title: 'Passcode',
            dataIndex: 'passcode',
            render: (passcode: string) => <Text copyable>{passcode}</Text>
        },
        {
            title: 'SMS passcode',
            render: () => <Text copyable>{SMS_OTP}</Text>
        }
    ]

    const balance = data?.company.balance
    const country = data?.company.country
    return (
        <div>
            <Descriptions
                title={
                    <Space>
                        <ArrowLeftOutlined onClick={onGoBack} />
                        {data?.company.name}
                    </Space>
                }
            >
                <Descriptions.Item label="Country">
                    {country ? getCountryName(country) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Wallet Balance">
                    {balance ? formatCurrency(balance.value, balance.currency) : '-'}
                </Descriptions.Item>
            </Descriptions>
            <Table dataSource={data?.employees} columns={columns} rowKey="email" />
        </div>
    )
}

export default DemoAccount
