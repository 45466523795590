import React, {FC, useEffect, useState} from 'react'
import {Alert, notification} from 'antd'

import WhitelistDataContainer from './whitelist-data'
import WhitelistExistent from './whitelist-existent'
import WhitelistNew, {FormValues as WhitelistNewFormValues} from './whitelist-new'
import WhitelistPending from './whitelist-pending'
import {useWhitelists, useWhitelistsByEmail} from 'services/whitelist'
import {useHubspotConnections} from 'services/hubspot'
import {WhitelistOptions, HubspotConnection, Whitelist} from 'types/whitelist'
import {SomethingWentWrong} from 'components/page-message'

import * as s from './whitelist.styles'

interface Props {
    whitelists: Whitelist[]
    hubspotConnections: HubspotConnection[]
    hubspotCompanyName: string
    companyIdForWhitelistedEmail: string
    onWhitelistNewSubmit: (values: WhitelistNewFormValues) => void
    onRemoveConnections: () => void
    onRemoveWhitelistForEmail: () => void
}

export const WhitelistPage: FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    whitelists,
    hubspotConnections,
    hubspotCompanyName,
    companyIdForWhitelistedEmail,
    onWhitelistNewSubmit,
    onRemoveConnections,
    onRemoveWhitelistForEmail
}) => {
    const hasConnection = hubspotConnections?.some(
        (connection: HubspotConnection) => connection.companyId
    )
    const showWhitelistNew = whitelists.length === 0 && hubspotConnections.length === 0
    const showWhitelistPending = whitelists.length > 0 && !hasConnection
    const showWhitelistExistent = hasConnection
    const showWhitelistData = hubspotConnections.length > 0

    const noWhitelistInKerberosButPendingWhitelistInPuck =
        whitelists.length === 0 && hubspotConnections.length > 0 && !hasConnection
    const showInconsistentState = noWhitelistInKerberosButPendingWhitelistInPuck

    return (
        <>
            {showInconsistentState && (
                <s.AlertContainer>
                    <Alert
                        showIcon
                        type="error"
                        message="This is an inconsistent state, if refreshing the page (F5) doesn't work, try the following: This connection was probably removed before, but not completely. To resolve this, you need to click remove connection👇🏾 and then re-connect to the Pleo company."
                    />
                </s.AlertContainer>
            )}
            {showWhitelistNew && (
                <WhitelistNew
                    company={hubspotCompanyName}
                    companyIdForWhitelistedEmail={companyIdForWhitelistedEmail}
                    onSubmit={onWhitelistNewSubmit}
                    removeWhitelist={onRemoveWhitelistForEmail}
                />
            )}
            {showWhitelistPending && (
                <WhitelistPending company={hubspotCompanyName} whitelists={whitelists} />
            )}
            {showWhitelistExistent && <WhitelistExistent hubspotCompanyName={hubspotCompanyName} />}
            {showWhitelistData && (
                <WhitelistDataContainer
                    whitelistData={hubspotConnections}
                    onRemoveConnections={onRemoveConnections}
                />
            )}
        </>
    )
}
const WhitelistContainer: FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const [existingWhitelistForEmail, setExistingWhitelistForEmail] = useState<Whitelist | null>(
        null
    )
    const [alreadyWhitelistedEmail, setAlreadyWhitelistedEmail] = useState('')

    const sessionStorage = {
        company: window.sessionStorage.getItem('company') || '',
        email: window.sessionStorage.getItem('email') || '',
        name: window.sessionStorage.getItem('name') || ''
    }

    const {
        data: whitelists,
        mutations: {updateWhitelists, deleteWhitelists}
    } = useWhitelists(sessionStorage.company)

    const {
        data: whitelistsForEmail,
        mutations: {deleteWhitelists: deleteWhitelistsForEmail}
    } = useWhitelistsByEmail(alreadyWhitelistedEmail)

    const {
        mutations: {deleteHubspotConnections: deleteConnectionsForEmail}
    } = useHubspotConnections(existingWhitelistForEmail?.data.hubspotCompanyId)

    useEffect(() => {
        if (whitelistsForEmail && whitelistsForEmail.length > 0) {
            const whitelistForEmail = whitelistsForEmail[0]
            setExistingWhitelistForEmail(whitelistForEmail)
        }
    }, [whitelistsForEmail])

    const {
        data: hubspotConnections,
        mutations: {deleteHubspotConnections}
    } = useHubspotConnections(sessionStorage.company)

    const onWhitelistNewSubmit = async (values: WhitelistNewFormValues) => {
        setExistingWhitelistForEmail(null)
        const options: WhitelistOptions = {
            accountManagerEmail: sessionStorage.email,
            hubspotCompanyId: sessionStorage.company
        }
        if (!values.email) {
            return
        }
        try {
            await updateWhitelists(values.email, options)
        } catch (e: any) {
            const message =
                e?.message === 'user exists'
                    ? 'This user already exists. Contact Compliance if you want to delete the user and add a new whitelist.'
                    : e?.message
            notification.error({
                message: 'Failed to create whitelist',
                description: message
            })
            setAlreadyWhitelistedEmail(values.email)
        }
    }

    const onRemoveConnections = async () => {
        try {
            await deleteWhitelists()
        } catch (e: any) {
            notification.error({
                message: `Failed to remove whitelist(s)`,
                description: e?.message
            })
        }
        try {
            await deleteHubspotConnections()
        } catch (e: any) {
            notification.error({
                message: 'Failed to remove connection',
                description: e?.message
            })
        }
        setExistingWhitelistForEmail(null)
        setAlreadyWhitelistedEmail('')
    }

    const onRemoveWhitelistForEmail = async () => {
        let error = false
        try {
            await deleteWhitelistsForEmail()
        } catch (e: any) {
            error = true
            notification.error({
                message: 'Failed to remove whitelist for email',
                description: e?.message
            })
        }
        try {
            await deleteConnectionsForEmail()
        } catch (e: any) {
            error = true
            notification.error({
                message: 'Failed to remove connections for email',
                description: e?.message
            })
        }
        if (!error) {
            notification.info({message: 'Successfully removed whitelist'})
            setExistingWhitelistForEmail(null)
            setAlreadyWhitelistedEmail('')
        }
    }

    if (!sessionStorage.company) {
        return <SomethingWentWrong />
    }

    return (
        <WhitelistPage
            whitelists={whitelists || []}
            hubspotConnections={hubspotConnections || []}
            hubspotCompanyName={sessionStorage.name}
            companyIdForWhitelistedEmail={existingWhitelistForEmail?.data.hubspotCompanyId ?? ''}
            onWhitelistNewSubmit={onWhitelistNewSubmit}
            onRemoveConnections={onRemoveConnections}
            onRemoveWhitelistForEmail={onRemoveWhitelistForEmail}
        />
    )
}
export default WhitelistContainer
