import React, {FC} from 'react'
import {Table, Button, Typography} from 'antd'
import {useParams} from 'react-router-dom'
import {useBillingInvoicesSummary} from 'services/billing'
import {Amount} from '@pleo-io/deimos'
import {getTagStatusComponent} from './utils'

const {Text} = Typography

const columns = [
    {
        title: 'Number',
        dataIndex: 'number',
        key: 'number'
    },
    {
        title: 'Date',
        dataIndex: 'dueDate',
        key: 'dueDate'
    },
    {
        title: 'Amount',
        dataIndex: 'total',
        key: 'total',
        render: (total: Amount) => (
            <Text>
                {total.value.toFixed(2)} {total.currency.toUpperCase()}
            </Text>
        )
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status: string) => getTagStatusComponent(status)
    },
    {
        title: 'Download',
        dataIndex: 'invoicePdfUrl',
        key: 'invoicePdfUrl',
        render: (url: string) => (
            <Button type="link" href={url} style={{padding: 0}}>
                Download
            </Button>
        )
    }
]

const CustomerInformation: FC = () => {
    const {id: deimosCompanyId} = useParams<{id: string}>()
    const {data} = useBillingInvoicesSummary(deimosCompanyId || '')

    return (
        <div>
            <h3>{`Invoices (${data.length})`}</h3>
            <Table bordered dataSource={data ? data : []} columns={columns} size="middle" />
        </div>
    )
}

export default CustomerInformation
