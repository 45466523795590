import {PageOrder, PaginationData} from '@pleo-io/deimos'
import {useState} from 'react'

export interface PaginationRequest<SortableProperties extends string> {
    sorting: Record<SortableProperties, PageOrder>[]
    limit?: number | null
    after?: string | null
    before?: string | null
}

export function useCursorPagination<SortableProperties extends string>(
    defaultPagination: PaginationRequest<SortableProperties>
) {
    const [paginationRequest, setPaginationRequest] =
        useState<PaginationRequest<SortableProperties>>(defaultPagination)

    const [currentPagination, setCurrentPagination] = useState<PaginationData<SortableProperties>>()

    const nextPage = () => {
        if (currentPagination?.hasNextPage) {
            setPaginationRequest(({limit, sorting}) => ({
                limit,
                sorting,
                after: currentPagination.endCursor
            }))
        }
    }

    const previousPage = () => {
        if (currentPagination?.hasPreviousPage) {
            setPaginationRequest(({limit, sorting}) => ({
                limit,
                sorting,
                before: currentPagination.startCursor
            }))
        }
    }

    const updateSorting = (sorting: typeof defaultPagination.sorting) => {
        setPaginationRequest((prev) => ({
            ...prev,
            sorting
        }))
    }

    const updateLimit = (limit: number) => {
        setPaginationRequest((prev) => ({
            sorting: prev.sorting,
            limit
        }))
    }

    return {
        paginationRequest,
        updateLimit,
        updateSorting,
        previousPage,
        nextPage,
        setCurrentPagination
    }
}
