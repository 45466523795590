import useSWR from 'swr'
import {getDeimos} from 'services/deimos'
import {apiGatewayRequest} from 'services/request/handler'
import {
    CouponType,
    Plan,
    PlanFamily,
    EstimatePricingRequest,
    EstimatePricingResponse
} from 'types/billing-plan'

export const usePlans = (family: PlanFamily, countryCode?: string) =>
    useSWR<Plan[]>(
        countryCode ? `rest/v1/billing/plans?country=${countryCode}&filter=${family}` : '',
        getDeimos,
        {
            refreshInterval: 0,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    )

export const useCoupons = (type?: CouponType) => {
    const couponEndpoint = type ? `rest/v1/billing/coupons?type=${type}` : 'rest/v1/billing/coupons'
    return useSWR(couponEndpoint, getDeimos, {revalidateOnFocus: false})
}

export function getEstimateBilling(
    companyId: string,
    body: EstimatePricingRequest
): Promise<EstimatePricingResponse> {
    return apiGatewayRequest()
        .post(`rest/v1/billing/companies/${companyId}/estimate-billing-price`, {json: body})
        .then((res: any) => res.json())
}

export const useBillingInvoicesSummary = (deimosCompanyId: string) => {
    const url = `rest/v1/billing/invoices/companies/${deimosCompanyId}/summary`

    return useSWR(url, getDeimos, {
        revalidateOnFocus: false,
        shouldRetryOnError: true
    })
}
