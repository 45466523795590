import React, {FC} from 'react'
import {Table, Tooltip} from 'antd'
import {FailIcon, SuccessIcon} from 'theme/styled'
import {ReactComponent as StateBlank} from 'resources/state-blank.svg'
import {
    AdminVerification,
    CompanyStakeholderResponse,
    DocumentCategory,
    KycInformation,
    KycInformationName,
    PersonNameModel
} from 'types/kyc-overview'
import {
    exhaustiveCheck,
    getLatestEkycCheck,
    getLatestIdvCheck,
    getPersonName,
    validateAddress,
    validateDateOfBirth
} from 'utils'

export interface KycTableData {
    id: string
    name?: PersonNameModel
    poi: DocumentStatus
    poa: DocumentStatus
    address: DocumentStatus
    dob: DocumentStatus
    nationalId: DocumentStatus
    idv: CheckStatus
    eKYC: CheckStatus
}

export enum DocumentStatus {
    received = 'received',
    unnecessary = 'unnecessary',
    missing = 'missing'
}

export enum CheckStatus {
    pass = 'PASS',
    fail = 'FAIL',
    undefined = 'UNDEFINED'
}

const determineDocumentStatus = (isReceived: boolean, isMissing: boolean) => {
    if (isReceived) return DocumentStatus.received
    return isMissing ? DocumentStatus.missing : DocumentStatus.unnecessary
}

const determineEKYCStatus = (manager: CompanyStakeholderResponse) => {
    if (!manager?.ekycChecks?.length) {
        return CheckStatus.undefined
    }
    const eKYC = getLatestEkycCheck(manager)
    return eKYC.expiresAt ? CheckStatus.fail : (eKYC.result as CheckStatus)
}

const determineIDVStatus = (manager: CompanyStakeholderResponse) =>
    ((manager?.idvChecks?.length || 0) > 0 && (getLatestIdvCheck(manager).result as CheckStatus)) ||
    CheckStatus.undefined

const determineAdminVerificationStatus = (manager: CompanyStakeholderResponse) => {
    const verificationsWithResult = manager.verifications?.filter(
        (verification: AdminVerification) => verification.result
    )
    const lastVerification = verificationsWithResult
        ? verificationsWithResult[verificationsWithResult.length - 1]
        : null

    return lastVerification ? lastVerification.result : CheckStatus.undefined
}

const renderDocument = (status: DocumentStatus) => {
    switch (status) {
        case DocumentStatus.received:
            return (
                <Tooltip title="Received">
                    <SuccessIcon data-testid="received" />
                </Tooltip>
            )
        case DocumentStatus.unnecessary:
            return (
                <Tooltip title="Unnecessary">
                    <StateBlank data-testid="unnecessary" />
                </Tooltip>
            )
        case DocumentStatus.missing:
            return (
                <Tooltip title="Missing">
                    <FailIcon data-testid="missing" />
                </Tooltip>
            )
        default:
            return exhaustiveCheck(status)
    }
}

const renderCheck = (status: CheckStatus) => {
    switch (status) {
        case CheckStatus.pass:
            return <span>{CheckStatus.pass}</span>
        case CheckStatus.fail:
            return <span>{CheckStatus.fail}</span>
        case CheckStatus.undefined:
            return <span>-</span>
        default:
            return exhaustiveCheck(status)
    }
}

export const toKycTable = (
    missingCompanyInfo?: KycInformation[],
    stakeholders?: CompanyStakeholderResponse[]
) => {
    return stakeholders
        ?.filter((manager) => manager.kycPerson)
        .map((manager) => {
            const hasDocumentFileUploaded = (category: DocumentCategory) => {
                const hasDocument = manager?.documents?.find(
                    (document) => document.category === category
                )
                return !!hasDocument && hasDocument?.files?.length > 0
            }

            const isMissingInformation = (name: KycInformationName) =>
                !!missingCompanyInfo &&
                missingCompanyInfo.some(
                    (info: KycInformation) => info.name === name && info.subjectId === manager.id
                )

            return {
                id: manager.id,
                name: manager.name,
                poi: determineDocumentStatus(
                    hasDocumentFileUploaded(DocumentCategory.PROOF_OF_ID),
                    isMissingInformation(KycInformationName.PROOF_OF_ID)
                ),
                poa: determineDocumentStatus(
                    hasDocumentFileUploaded(DocumentCategory.PROOF_OF_ADDRESS),
                    isMissingInformation(KycInformationName.PROOF_OF_ADDRESS)
                ),
                address: determineDocumentStatus(
                    validateAddress(manager),
                    isMissingInformation(KycInformationName.ADDRESS)
                ),
                dob: determineDocumentStatus(
                    validateDateOfBirth(manager),
                    isMissingInformation(KycInformationName.BIRTH_DATE)
                ),
                nationalId: determineDocumentStatus(
                    !!manager.nationalId,
                    isMissingInformation(KycInformationName.NATIONAL_ID)
                ),
                idv: determineIDVStatus(manager),
                eKYC: determineEKYCStatus(manager),
                adminVerification: determineAdminVerificationStatus(manager)
            }
        })
}

export const KycTable: FC<
    React.PropsWithChildren<
        React.PropsWithChildren<{
            dataSource?: KycTableData[]
        }>
    >
> = ({dataSource}) => {
    const columns = [
        {title: 'Name', dataIndex: 'name', render: getPersonName},
        {title: 'PoI', dataIndex: 'poi', render: renderDocument},
        {title: 'PoA', dataIndex: 'poa', render: renderDocument},
        {title: 'Address', dataIndex: 'address', render: renderDocument},
        {title: 'DoB', dataIndex: 'dob', render: renderDocument},
        {
            title: 'National ID',
            dataIndex: 'nationalId',
            render: renderDocument
        },
        {title: 'IDV', dataIndex: 'idv', render: renderCheck},
        {title: 'Admin Verification', dataIndex: 'adminVerification', render: renderCheck},
        {title: 'eKYC', dataIndex: 'eKYC', render: renderCheck}
    ]
    return (
        <Table dataSource={dataSource} rowKey={'id'} style={{padding: '35px'}} columns={columns} />
    )
}

const KycTableContainer: FC<
    React.PropsWithChildren<
        React.PropsWithChildren<{
            missingInformation?: KycInformation[]
            managers: CompanyStakeholderResponse[]
        }>
    >
> = ({missingInformation, managers}) => (
    <KycTable dataSource={toKycTable(missingInformation, managers)} />
)
export default KycTableContainer
