import {dionysusRequest} from 'services/request/handler'

export function getDionysus(route: string) {
    return dionysusRequest()
        .get(route)
        .then((res: Response) => res.json())
}

export function deleteDionysus(route: string) {
    return dionysusRequest().delete(route)
}

export function postDionysus(route: string, body?: Record<string, any>) {
    return dionysusRequest().post(route, {
        json: body
    })
}

export function patchDionysus(route: string, body?: Record<string, any>) {
    return dionysusRequest().patch(route, {
        json: body
    })
}

export function putDionysus(route: string, body?: Record<string, any>) {
    return dionysusRequest().put(route, {
        json: body
    })
}
