import {parse} from 'qs'
import {useLocation} from 'react-router-dom'

interface Params {
    userEmail?: string
}

export const useUserEmail = () => {
    const location = useLocation()
    const {userEmail}: Params = parse(location.search, {ignoreQueryPrefix: true})

    return userEmail
}
