import React, {FC} from 'react'
import {Typography} from 'antd'
import moment from 'moment'

import {HubspotConnection} from 'types/whitelist'
import {useCompany} from 'services/company'
import IgnoreErrorBoundary from 'providers/notify-error-boundary'

import * as s from './whitelist.styles'
import RemoveConnection from 'components/remove-connection'

const {Text} = Typography

interface Props {
    whitelistData: HubspotConnection[]
    onRemoveConnections: () => void
}

const WhitelistDataContainer: FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    whitelistData,
    onRemoveConnections
}) => {
    const checkBoxMessage = whitelistData.some((d) => d.companyId)
        ? 'I understand that removing this connection will reset HubSpot Company information and remove it from HubSpot'
        : 'I understand that this will remove the whitelist'
    return (
        <>
            {whitelistData.map((data, index) => {
                return (
                    <div key={`connection${index}`}>
                        <s.HubspotConnectionContainer>
                            <s.InfoColumn>
                                <Text strong>Pleo Company ID</Text>
                                <Text copyable>{data.companyId || 'Not signed up'}</Text>
                            </s.InfoColumn>
                            <s.InfoColumn>
                                <Text strong>Pleo Company Name</Text>
                                <Text>
                                    <IgnoreErrorBoundary fallbackMessage={'Company was deleted'}>
                                        <CompanyName companyId={data.companyId} />
                                    </IgnoreErrorBoundary>
                                </Text>
                            </s.InfoColumn>
                            <s.InfoColumn>
                                <Text strong>Whitelisted Email</Text>
                                <Text>{data.emailWhitelisted || 'Unknown'}</Text>
                            </s.InfoColumn>
                            <s.InfoColumn>
                                <Text strong>Whitelisted by</Text>
                                <Text>{data.accountManagerEmail || 'Unknown'}</Text>
                            </s.InfoColumn>
                            <s.InfoColumn>
                                <Text strong>Date</Text>
                                <Text>{moment(data.createdAt).format('lll')}</Text>
                            </s.InfoColumn>
                        </s.HubspotConnectionContainer>
                    </div>
                )
            })}
            <RemoveConnection
                plural={whitelistData.length > 1}
                checkBoxMessage={checkBoxMessage}
                removeConnections={onRemoveConnections}
            />
        </>
    )
}

export default WhitelistDataContainer

const CompanyName = ({companyId}: {companyId: string}) => {
    const {data: company} = useCompany(companyId)
    return <>{company?.name || 'Not signed up'}</>
}
