/* eslint-disable no-console */
import React, {useEffect, useState} from 'react'
import {
    Form,
    Typography,
    Button,
    Space,
    Modal,
    Select,
    Checkbox,
    message,
    Skeleton,
    Result,
    Input,
    notification
} from 'antd'
import {
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    MinusCircleTwoTone,
    PlusOutlined
} from '@ant-design/icons'
import {useCompany, useCompanies, CompanyWithOrganizationId} from 'services/company'

import {
    useCompanyRegistrySearch,
    useSupportedCompanyRegistrationLocales
} from 'services/company-registry'
import {GetCompanySearchResponse} from '@pleo-io/deimos'
import {
    createOrganizationGroupingRequest,
    OrganizationGroupingRequest
} from 'services/organizations'
import styled from 'styled-components'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'
import {
    ConfirmationModalDataType,
    FormType,
    getConfirmationModalData,
    getConfirmationModalTitle,
    hasPendingOrganizationRequestAction,
    pleoIdPattern,
    useCompanyGroupingData
} from './utils'
import ConfirmModalContent from './confirm-modal'
import {Link, useParams} from 'react-router-dom'
const {Text} = Typography
const {confirm} = Modal

export const UpdateCompanyGroupingForm = () => {
    const [companiesQuery, setCompaniesQuery] = useState('')
    const {data: companies} = useCompanies(companiesQuery)
    const {organizationId} = useParams<{organizationId: string}>()
    const [companyIdQuery, setCompanyIdQuery] = useState('')
    const {data: companyById} = useCompany(companyIdQuery)
    const {data: supportedLocales} = useSupportedCompanyRegistrationLocales()
    const companiesData = React.useMemo(
        () => Array.from(new Set([...(companies ?? []), ...(companyById ? [companyById] : [])])),
        [companies, companyById]
    )

    const {
        companies: styxCompanies,
        setCountryCode,
        setQuery: setStyxQuery
    } = useCompanyRegistrySearch()

    return (
        <CompanyGrouping
            organizationId={organizationId}
            companies={companiesData}
            styxCompanies={styxCompanies}
            setCountryCode={setCountryCode}
            setStyxQuery={setStyxQuery}
            supportedLocales={supportedLocales}
            onCompanyNameSearch={setCompaniesQuery}
            onCompanyIdSearch={setCompanyIdQuery}
        />
    )
}

const CompanyGrouping = ({
    companies,
    styxCompanies,
    setCountryCode,
    setStyxQuery,
    onCompanyNameSearch,
    onCompanyIdSearch,
    supportedLocales,
    organizationId
}: {
    organizationId?: string
    companies?: CompanyWithOrganizationId[]
    supportedLocales?: string[]
    styxCompanies?: GetCompanySearchResponse[]
    setCountryCode: (query: string) => void
    setStyxQuery: (query: string) => void
    onCompanyNameSearch: (query: string) => void
    onCompanyIdSearch: (query: string) => void
}) => {
    const data = useCompanyGroupingData({organizationId})
    const [organizationName, setOrganizationName] = React.useState(data?.organization?.name ?? '')
    const [hasNameInputError, setHasNameInputError] = React.useState(false)
    const [isSaving, setIsSaving] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)

    const [form] = Form.useForm()
    const [customerConsent, setCustomerConsent] = useState(false)
    const entities = data?.organization?.companies?.map((entity) => ({
        value: entity.companyId,
        label: entity.name
    })) ?? [{value: '', label: ''}]

    const companyOptions = (companies ?? []).map((company) => ({
        value: company.id,
        label: company.name
    }))

    useEffect(() => {
        form.setFieldsValue({
            existingEntites: entities
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entities.length])

    const onSearchExistingEntity = (query: string) => {
        if (query.match(pleoIdPattern)) {
            onCompanyIdSearch(query)
        } else {
            onCompanyNameSearch(query)
        }
    }

    const onSetCountryCode = (value: string) => {
        setCountryCode(value)
    }

    const onSearchStyxEntity = (query: string) => {
        // We want to initalise searching the company here as well to be able to check if there is an existing company with a matching registration number
        onCompanyNameSearch(query)
        setStyxQuery(query)
    }

    const onFinish = (values: FormType) => {
        if (!data?.organization?.name || !data?.organization?.id) {
            return
        }
        const {confirmationModalData, groupingRequestData} = getConfirmationModalData({
            values,
            entities,
            selectedOrganization: {
                label: data?.organization?.name,
                value: data?.organization?.id
            }
        })
        showConfirm(confirmationModalData, groupingRequestData)
    }

    const showConfirm = (
        confirmationModalData: ConfirmationModalDataType,
        groupingRequestData: OrganizationGroupingRequest
    ) => {
        confirm({
            title: getConfirmationModalTitle(confirmationModalData),
            icon: <ExclamationCircleOutlined />,
            width: '1000px',
            content: <ConfirmModalContent confirmationModalData={confirmationModalData} />,
            onOk: async () => {
                try {
                    await createOrganizationGroupingRequest(groupingRequestData)
                    setIsSuccess(true)
                    message.success('A task was created and compliance will review this request.')
                } catch (error) {
                    message.error(
                        'There was an error creating this grouping task. Please refresh the page and try again.'
                    )
                }
            },
            onCancel() {
                setCustomerConsent(false)
            }
        })
    }

    const handleOrganizationNameInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setOrganizationName(e.target.value)
    }

    const handleOrganizationName = async () => {
        if (!organizationName || organizationName === data?.organization?.name) {
            setHasNameInputError(true)
            return
        }

        setIsSaving(true)
        try {
            await data.mutation.renameOrganization(organizationName.trim())
            notification.success({
                message: 'Successfully updated organization name'
            })
        } catch (e: any) {
            notification.error({
                message: 'Failed to remove connection',
                description: e?.message
            })
        } finally {
            setIsSaving(false)
        }
    }

    const onCheckbox = (e: CheckboxChangeEvent) => {
        setCustomerConsent(e.target.checked)
    }

    return (
        <Skeleton loading={data.isLoadingInitialData}>
            {isSuccess && (
                <Result
                    status="success"
                    title={`Successfully updated the grouping for ${data?.organization?.name}`}
                    extra={[
                        <Button type="primary" key="requests">
                            <Link to="../../requests">View requests</Link>
                        </Button>
                    ]}
                />
            )}
            {data.hasRelatedOrganization && !isSuccess && (
                <>
                    <Space direction="horizontal" style={{marginBottom: 20}}>
                        <Input
                            value={organizationName}
                            placeholder={
                                data?.organization?.name ?? 'Enter a valid organization name'
                            }
                            onChange={handleOrganizationNameInputChange}
                            status={hasNameInputError ? 'error' : undefined}
                        />
                        {organizationName && organizationName !== data?.organization?.name && (
                            <Button onClick={handleOrganizationName} loading={isSaving}>
                                Save
                            </Button>
                        )}
                    </Space>
                    <Typography.Paragraph copyable>
                        Org ID: {data?.organization?.id}
                    </Typography.Paragraph>
                    <Form
                        labelCol={{span: 24}}
                        form={form}
                        onFinish={onFinish}
                        initialValues={{
                            companyIdsToAdd: [{companyIdToAdd: undefined}]
                        }}
                    >
                        <Form.Item
                            required
                            label={`Entities in ${data?.organization?.name}`}
                            tooltip="This list shows all entities already existing in this group. You can click the minus icon to request removal of this entity from this organization."
                        >
                            <Form.List name="existingEntites">
                                {(fields, {remove}) => (
                                    <>
                                        {fields.map(({key, name, ...restField}) => (
                                            <ExistingEntitySearchWrapper
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: 8
                                                }}
                                                align="baseline"
                                            >
                                                <Form.Item {...restField} name={[name, 'value']}>
                                                    <Select labelInValue showArrow={false}>
                                                        {entities.map((company) => (
                                                            <Select.Option
                                                                key={company.value}
                                                                value={company.value}
                                                                label={company.label}
                                                            >
                                                                {company.label}{' '}
                                                                <Text type="secondary">
                                                                    ({company.value})
                                                                </Text>
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <MinusCircleTwoTone
                                                    twoToneColor={'#FF0000'}
                                                    onClick={() => remove(name)}
                                                />
                                            </ExistingEntitySearchWrapper>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                        <Form.Item
                            required
                            label="Add a registered Pleo company to this group"
                            tooltip="Use this to add companies already registered in Pleo to this company grouping."
                        >
                            <Form.List
                                name="companyIdsToAdd"
                                rules={[
                                    {
                                        validator: async (_, companyIdsToAdd) => {
                                            if (!data?.organization && companyIdsToAdd.length < 1) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Add at least one entity that already uses Pleo when creating a new Organization'
                                                    )
                                                )
                                            }
                                        }
                                    }
                                ]}
                            >
                                {(fields, {add, remove}, {errors}) => (
                                    <>
                                        {fields.map(({key, name, ...restField}) => (
                                            <ExistingEntitySearchWrapper
                                                key={key}
                                                style={{display: 'flex', marginBottom: 8}}
                                                align="baseline"
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'companyIdToAdd']}
                                                    validateTrigger={['onChange']}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message:
                                                                'Please add at least one existing entity'
                                                        },
                                                        {
                                                            validator: async (
                                                                _,
                                                                {value: companyId} = {}
                                                            ) => {
                                                                const {companyIdsToAdd = []} =
                                                                    form.getFieldsValue()
                                                                const values = companyIdsToAdd.map(
                                                                    ({companyIdToAdd}: any) =>
                                                                        companyIdToAdd?.value
                                                                )
                                                                const mappedValues = values.reduce(
                                                                    (acc: any, e: any) =>
                                                                        acc.set(
                                                                            e,
                                                                            (acc.get(e) || 0) + 1
                                                                        ),
                                                                    new Map()
                                                                )
                                                                if (
                                                                    !companyId ||
                                                                    mappedValues.get(companyId) ===
                                                                        1
                                                                ) {
                                                                    return Promise.resolve()
                                                                }
                                                                return Promise.reject(
                                                                    new Error(
                                                                        "The company you selected to add to this group is a duplicate. Please add another that you haven't already selected"
                                                                    )
                                                                )
                                                            }
                                                        },
                                                        {
                                                            validator: async (
                                                                _,
                                                                {value: companyId} = {}
                                                            ) => {
                                                                if (!companyId) {
                                                                    return Promise.resolve()
                                                                }

                                                                const hasPendingActions =
                                                                    await hasPendingOrganizationRequestAction(
                                                                        {companyId}
                                                                    )
                                                                if (!hasPendingActions) {
                                                                    return Promise.resolve()
                                                                }

                                                                return Promise.reject(
                                                                    new Error(
                                                                        'The company you selected to add to this group has a pending request action. Please add another without a pending action'
                                                                    )
                                                                )
                                                            }
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        autoClearSearchValue={false}
                                                        allowClear
                                                        filterOption={(input, option) => {
                                                            return (option?.label ?? '')
                                                                .toString()
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase())
                                                        }}
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '')
                                                                .toString()
                                                                .toLowerCase()
                                                                .localeCompare(
                                                                    (optionB?.label ?? '')
                                                                        .toString()
                                                                        .toLowerCase()
                                                                )
                                                        }
                                                        onSearch={onSearchExistingEntity}
                                                        placeholder="Look up company by name in Pleo"
                                                        showArrow={false}
                                                        showSearch
                                                        labelInValue
                                                    >
                                                        <Select.OptGroup label="Company">
                                                            {companyOptions.map((company) => (
                                                                <Select.Option
                                                                    key={company.value}
                                                                    value={company.value}
                                                                    label={company.label}
                                                                >
                                                                    {company.label}{' '}
                                                                    <Text type="secondary">
                                                                        ({company.value})
                                                                    </Text>
                                                                </Select.Option>
                                                            ))}
                                                        </Select.OptGroup>
                                                    </Select>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </ExistingEntitySearchWrapper>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add existing Pleo company
                                            </Button>
                                        </Form.Item>
                                        <Form.ErrorList errors={errors} />
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                        <Form.Item
                            label="Sign up a new company to Pleo and add to this group"
                            tooltip="This is to onboard a new entity that is not yet using Pleo. To use this, select the country code and search by company name. Ensure the registration number matches the one the customer provided you with."
                        >
                            <Form.List name="registryIdsToCreate">
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, ...restField}) => (
                                            <StyxSearchWrapper
                                                key={key}
                                                style={{display: 'flex', marginBottom: 8}}
                                                align="baseline"
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'baseline'
                                                    }}
                                                >
                                                    <Select
                                                        style={{
                                                            width: 150
                                                        }}
                                                        placeholder="i.e GB"
                                                        onSelect={onSetCountryCode}
                                                    >
                                                        {supportedLocales?.map((country) => (
                                                            <Select.Option
                                                                key={country}
                                                                value={country}
                                                            >
                                                                {country}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>{' '}
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'registryIdToCreate']}
                                                        style={{width: '100%'}}
                                                        validateTrigger={['onChange']}
                                                        rules={[
                                                            {
                                                                validator: async (
                                                                    _,
                                                                    {value: registryId} = {}
                                                                ) => {
                                                                    const {
                                                                        registryIdsToCreate = []
                                                                    } = form.getFieldsValue()
                                                                    const values =
                                                                        registryIdsToCreate.map(
                                                                            ({
                                                                                registryIdToCreate
                                                                            }: any) =>
                                                                                registryIdToCreate?.value
                                                                        )
                                                                    const mappedValues =
                                                                        values.reduce(
                                                                            (acc: any, e: any) =>
                                                                                acc.set(
                                                                                    e,
                                                                                    (acc.get(e) ||
                                                                                        0) + 1
                                                                                ),
                                                                            new Map()
                                                                        )
                                                                    if (
                                                                        !registryId ||
                                                                        mappedValues.get(
                                                                            registryId
                                                                        ) === 1
                                                                    ) {
                                                                        return Promise.resolve()
                                                                    }
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            "The company you selected to sign up and add to this group is a duplicate. Please add another that you haven't already selected"
                                                                        )
                                                                    )
                                                                }
                                                            },
                                                            {
                                                                validator: async (
                                                                    _,
                                                                    {value: registryId}
                                                                ) => {
                                                                    if (!registryId) {
                                                                        return Promise.resolve()
                                                                    }

                                                                    const hasPendingActions =
                                                                        await hasPendingOrganizationRequestAction(
                                                                            {registryId}
                                                                        )
                                                                    if (!hasPendingActions) {
                                                                        return Promise.resolve()
                                                                    }

                                                                    return Promise.reject(
                                                                        new Error(
                                                                            'The company you selected to sign up and add to this group has a pending request action. Please add another without a pending action'
                                                                        )
                                                                    )
                                                                }
                                                            },
                                                            {
                                                                validator: async (
                                                                    _,
                                                                    {value: registryId} = {}
                                                                ) => {
                                                                    if (
                                                                        !registryId ||
                                                                        !companies?.find(
                                                                            (company) =>
                                                                                company?.registrationNumber?.includes(
                                                                                    registryId
                                                                                )
                                                                        )
                                                                    ) {
                                                                        return Promise.resolve()
                                                                    }

                                                                    return Promise.reject(
                                                                        new Error(
                                                                            'The company you selected to sign up already exists in Pleo. Please add that existing company instead of trying to add sign up a new one'
                                                                        )
                                                                    )
                                                                }
                                                            }
                                                        ]}
                                                    >
                                                        <Select
                                                            autoClearSearchValue={false}
                                                            filterOption={false}
                                                            onSearch={onSearchStyxEntity}
                                                            placeholder="Look up company by name or registration number in government registry"
                                                            showArrow={false}
                                                            showSearch
                                                            labelInValue
                                                        >
                                                            <Select.OptGroup label="Company">
                                                                {styxCompanies
                                                                    ?.slice(0, 5)
                                                                    .map(
                                                                        (
                                                                            company: GetCompanySearchResponse
                                                                        ) => (
                                                                            <Select.Option
                                                                                key={
                                                                                    company.registryId
                                                                                }
                                                                                value={
                                                                                    company.registryId
                                                                                }
                                                                                label={
                                                                                    company.legalName
                                                                                }
                                                                            >
                                                                                {company.legalName}{' '}
                                                                                <Text type="secondary">
                                                                                    (
                                                                                    {
                                                                                        company.registryId
                                                                                    }
                                                                                    )
                                                                                </Text>
                                                                            </Select.Option>
                                                                        )
                                                                    )}
                                                            </Select.OptGroup>
                                                        </Select>
                                                    </Form.Item>
                                                    <MinusCircleOutlined
                                                        style={{marginLeft: '6px'}}
                                                        onClick={() => remove(name)}
                                                    />
                                                </div>
                                            </StyxSearchWrapper>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Sign up new company
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                        <Form.Item
                            required
                            label="Do you have the customer's consent to group these entities?"
                            valuePropName="checked"
                        >
                            <Checkbox checked={customerConsent} onChange={onCheckbox}>
                                Yes
                            </Checkbox>
                        </Form.Item>
                        <Button type="primary" htmlType="submit" disabled={!customerConsent}>
                            Request update
                        </Button>
                    </Form>
                </>
            )}
        </Skeleton>
    )
}

const ExistingEntitySearchWrapper = styled(Space)`
    .ant-space-item:first-child {
        width: 100%;
    }
`

const StyxSearchWrapper = styled(Space)`
    .ant-space-item:first-child {
        width: 100%;
    }
`
