import React from 'react'
import {countries, getEmojiFlag} from 'countries-list'
import {Select, SelectProps} from 'formik-antd'
import {Alpha2Code, SupportedCountryResponse} from 'types/countries'
import {useSupportedCountries} from 'services/supported-countries'
import {Skeleton} from 'antd'

const {Option} = Select

const getCountry = (alpha2code: Alpha2Code) => countries[alpha2code as Alpha2Code]

const SupportedCountrySelect = (props: SelectProps) => {
    const {data: countries, isValidating} = useSupportedCountries()
    if (isValidating) {
        return <Skeleton />
    }
    return (
        <Select {...props} optionFilterProp="label">
            {countries?.map(({countryCode}: SupportedCountryResponse) => (
                <Option key={countryCode} value={countryCode} label={getCountry(countryCode).name}>
                    {`${getEmojiFlag(countryCode)} ${getCountry(countryCode).name}`}
                </Option>
            ))}
        </Select>
    )
}

export default SupportedCountrySelect
