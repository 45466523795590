import React from 'react'
import {Select, SelectProps} from 'formik-antd'
import {SupportedCountryResponse} from 'types/countries'
import {useSupportedCountries} from 'services/supported-countries'
import {Skeleton} from 'antd'
import {uniqBy} from 'lodash'

const {Option} = Select

interface LanguageLocalesType {
    locale: string
    language?: string
}

const getLanguageText = (locale: string) => {
    const languageNamesInEnglish = new Intl.DisplayNames(['en'], {
        type: 'language',
        languageDisplay: 'standard'
    })

    return {
        locale: locale,
        language: languageNamesInEnglish.of(locale)
    }
}

const SupportedLanguageSelect = (props: SelectProps) => {
    const {data: countries, isValidating} = useSupportedCountries()
    const uniqueLanguageLocales: LanguageLocalesType[] | undefined = countries
        ? countries.map(({locale}: SupportedCountryResponse) => {
              return getLanguageText(locale)
          })
        : undefined

    if (isValidating) {
        return <Skeleton />
    }
    return (
        <Select {...props} optionFilterProp="label">
            {uniqBy(uniqueLanguageLocales, 'locale')?.map(
                ({locale, language}: LanguageLocalesType) => (
                    <Option key={locale} value={locale} label={language}>
                        {language}
                    </Option>
                )
            )}
        </Select>
    )
}

export default SupportedLanguageSelect
