export enum Velocity {
    UNSET = 'UNSET',
    SDD = 'SDD',
    FDD = 'FDD',
    EDD = 'EDD',
    HEDD = 'HEDD',
    HEDD1 = 'HEDD1'
}

export enum SmallCaseVelocity {
    SDD = 'sdd',
    FDD = 'fdd',
    EDD = 'edd',
    HEDD = 'hedd',
    HEDD1 = 'hedd1'
}

export enum SubjectType {
    PERSON = 'PERSON',
    COMPANY = 'COMPANY'
}

export enum KycInformationType {
    DATA = 'DATA',
    DOCUMENT = 'DOCUMENT'
}

export enum KycInformationName {
    BANK_VERIFICATION_DETAILS = 'BANK_VERIFICATION_DETAILS', // company data
    ADDRESS = 'ADDRESS', // person data
    PERSONS_IN_SCOPE = 'PERSONS_IN_SCOPE', // no persons
    STRUCTURE = 'STRUCTURE', // no company structure
    NATIONAL_ID = 'NATIONAL_ID', // person data
    BIRTH_DATE = 'BIRTH_DATE', // person data
    PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS', // person or company document
    PROOF_OF_ID = 'PROOF_OF_ID', // person document
    COMPANY_REGISTRATION_CERTIFICATE = 'COMPANY_REGISTRATION_CERTIFICATE', // company document
    FINANCIAL_STATEMENT = 'FINANCIAL_STATEMENT', // company document
    OWNERSHIP = 'OWNERSHIP', // company document
    PROOF_OF_BUSINESS = 'PROOF_OF_BUSINESS', // company document
    SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS', // company document
    ARTICLES_OF_ASSOCIATION = 'ARTICLES_OF_ASSOCIATION', // company document
    PROOF_OF_DIRECTORS = 'PROOF_OF_DIRECTORS', // company document
    OTHER = 'OTHER', // company
    OTHER_DOCUMENT = 'OTHER_DOCUMENT', // person
    BUSINESS_EXPLANATION = 'BUSINESS_EXPLANATION', // company info
    AML_ANSWERS = 'AML_ANSWERS'
}

export interface KycInformation {
    name: KycInformationName
    type: KycInformationType
    subject: SubjectType
    subjectId: string
    details?: string | null
    documentId?: string | null
}

export interface Name {
    firstName?: string
    givenName?: string
    middleName?: string
    familyName?: string
}

export interface DateOfBirth {
    day: number
    month: number
    year: number
}

export interface KycCheck {
    id: string
    result: string
    request: string
    response: string
    created: string
    updated?: string
    expiresAt?: string
    transactionMessage?: string
    falsePositive: boolean
    providerId?: string
    provider?: string
}

export interface AdminVerification {
    id: string
    personId: string
    provider?: string
    providerResponse?: string
    providerId?: string
    redirectUrl?: string
    falsePositive: boolean
    result?: string
    notes?: string
    errors: string[]
    createdAt: string
    updatedAt: string
}

export type IdvCheck = KycCheck
export type EkycCheck = KycCheck
export type PepCheck = KycCheck
export type SisCheck = KycCheck

export interface DocumentFile {
    id: string
}

export enum DocumentCategory {
    PROOF_OF_ID = 'PROOF_OF_ID',
    PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
    COMPANY_REGISTRATION_CERTIFICATE = 'COMPANY_REGISTRATION_CERTIFICATE',
    FINANCIAL_STATEMENT = 'FINANCIAL_STATEMENT',
    OWNERSHIP = 'OWNERSHIP',
    PROOF_OF_BUSINESS = 'PROOF_OF_BUSINESS',
    SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS',
    ARTICLES_OF_ASSOCIATION = 'ARTICLES_OF_ASSOCIATION',
    PROOF_OF_DIRECTORS = 'PROOF_OF_DIRECTORS',
    OTHER = 'OTHER'
}

export interface DocumentRequest {
    category: DocumentCategory
    title?: string
    description?: string
    effectiveDate?: string
    expirationDate?: string
    hidden?: boolean
}

export interface Document extends DocumentRequest {
    id: string
    personId?: string
    companyId?: string
    files: DocumentFile[]
}
export interface Company {
    id: string
    deimosId?: string
    globalId?: string
    registryId?: string
    registrationNumber: string
    legalName?: string
    address: PostalAddress
    sdd?: boolean
    fdd?: boolean
    edd?: boolean
    hedd?: boolean
    hedd1?: boolean
    kycCheckpoints?: KycStatus[]
    incorporationType?: string
    incorporationDate?: string
    memorandumDate?: string
    riskAssessment?: RiskAssessment
    deimosCreatedAt?: string
    documents?: Document[]
    kycPersons?: string[]
    velocity?: Velocity
    name: string
    createdAt: string
    updatedAt: string | null
    deletedAt: string | null
    styxId?: string | null
    ownerEmail: string | null
    ownerId: string | null
    domainName: string
    manualSignup: boolean
    status: Status
    trial: string | null
    organizationId?: string | null
}

export interface Status {
    kyc?: {
        [value in SmallCaseVelocity]?: boolean
    }
    [key: string]: unknown
}

export const VelocityPriority: Record<SmallCaseVelocity, number> = {
    sdd: 1,
    fdd: 2,
    edd: 3,
    hedd: 4,
    hedd1: 5
}

export interface PostalAddress extends Address {
    attention?: string
    company?: string
    phone?: string
}

export interface Address {
    addressLine1: string
    addressLine2?: string
    postalCode: string
    locality: string
    region?: string
    country: string
}

export interface DetailedAddress {
    country: string
    locality: string
    postalCode: string
    houseNumber: string
    street: string
    floor?: string
    region?: string
}

export interface KycStatus {
    id: string
    performed: string
    level: string
    nextReview?: string
    userEmail?: string
    userId?: string
    notes?: string
}

export interface RiskAssessment {
    id: string
    riskAssessmentId: string
    ownerId: string
    companyId: string
    score: string
    result: string
    ownerName: string
    assessmentDate: string
    checkIds?: string[]
    notes?: string
    checks?: RiskCheck[]
    history?: any[]
}

export interface RiskCheck {
    id: string
    companyId: string
    riskType: string
    score?: string
    ownerName?: string
    ownerId?: string
    checkDate?: string
    notes?: string
}

export interface CompanyStakeholderResponse {
    id: string
    address?: DetailedAddress
    companyStakeholderType?: CompanyStakeholderType[] | null
    dateOfBirth?: Partial<DateOfBirth>
    directorOf?: string[]
    documents?: Document[]
    ekycChecks?: EkycCheck[]
    entityType: string
    excluded?: boolean
    foreign?: boolean
    gender?: string | null
    idvChecks?: IdvCheck[]
    isAdmin?: boolean | null
    isUbo: boolean
    kycNationality?: KycNationality | null
    kycCitizenships?: KycCitizenship[] | null
    kycPerson: boolean
    missing: boolean
    name: PersonNameModel | undefined
    nationalId?: string | null
    ownershipPercentage?: number | null
    ownershipPercentageRange?: Range | null
    pepChecks?: PepCheck[]
    registryId?: string | null
    roles?: string[]
    rootCompanyId?: string | null
    shareholderOf?: Set<string>
    sisChecks?: SisCheck[]
    userId?: string | null
    verifications?: AdminVerification[]
}

type CompanyStakeholderType = 'UBO' | 'DIRECTOR' | 'ADMIN' | 'SHAREHOLDER'

export interface KycNationality {
    nationality: string
    source: NationalitySource
}

type NationalitySource =
    | 'COMPANIES_HOUSE'
    | 'CREDITSAFE_GLOBAL'
    | 'CREDITSAFE_SWEDEN'
    | 'VIRK'
    | 'VEMBI'
    | 'BVD'
    | 'USER_INPUT'
    | 'DUE_DILIGENCE_AGENT_INPUT'

export interface KycCitizenship {
    nationality: string
    source: CitizenshipSource
}

type CitizenshipSource =
    | 'COMPANIES_HOUSE'
    | 'CREDITSAFE_GLOBAL'
    | 'CREDITSAFE_SWEDEN'
    | 'VIRK'
    | 'VEMBI'
    | 'BVD'
    | 'USER_INPUT'
    | 'DUE_DILIGENCE_AGENT_INPUT'

export interface PersonNameModel {
    title?: string | null
    givenName?: string | null
    middleName?: string | null
    familyName?: string | null
    suffix?: string | null
}
