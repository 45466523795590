import React, {FC} from 'react'
import {Alert as AntdAlert} from 'antd'

const Alert: FC<
    React.PropsWithChildren<
        React.PropsWithChildren<{
            type: 'warning' | 'success' | 'info' | 'error' | undefined
            message: string
        }>
    >
> = ({type, message}) => (
    <AntdAlert
        showIcon
        type={type}
        style={{
            backgroundColor: 'transparent',
            borderStyle: 'none',
            paddingBottom: 0
        }}
        message={message}
    />
)
export default Alert
