import React, {FC} from 'react'
import {Divider, Typography} from 'antd'

import {Whitelist} from 'types/whitelist'
import Alert from 'components/alert'

import * as s from './whitelist.styles'

const {Text} = Typography

const WhitelistPending: FC<
    React.PropsWithChildren<
        React.PropsWithChildren<{
            company: string
            whitelists: Whitelist[]
        }>
    >
> = ({company, whitelists}) => {
    return (
        <>
            <s.InfoContainer>
                <s.InfoColumn>
                    <Text strong>HubSpot Company</Text>
                    <Text>{company}</Text>
                </s.InfoColumn>
                <s.InfoColumn>
                    <Text strong>Pending whitelist</Text>
                    <Text>{whitelists[0].emailPattern}</Text>
                </s.InfoColumn>
            </s.InfoContainer>
            <Divider />
            <s.AlertContainer>
                <Alert
                    type="warning"
                    message="The company contact has been whitelisted but has not created a Pleo account yet"
                />
                <Alert
                    type="info"
                    message="You can only whitelist one email per company, but you can remove this email and whitelist a new company contact if necessary"
                />
            </s.AlertContainer>
            <Divider />
        </>
    )
}

export default WhitelistPending
