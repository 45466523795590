export default function formatCurrency(
    amount: string | number,
    currency: string,
    options?: Intl.NumberFormatOptions
) {
    return new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency,
        ...options
    }).format(Number(amount))
}
