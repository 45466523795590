import React, {FC} from 'react'
import {Divider, Typography} from 'antd'

import Alert from 'components/alert'

import * as s from './whitelist.styles'

const {Text} = Typography

export const WhitelistExistent: FC<
    React.PropsWithChildren<
        React.PropsWithChildren<{
            hubspotCompanyName: string
        }>
    >
> = ({hubspotCompanyName}) => {
    return (
        <>
            <s.Container>
                <s.InfoColumn>
                    <Text strong>HubSpot Company</Text>
                    <Text>{hubspotCompanyName}</Text>
                </s.InfoColumn>
            </s.Container>
            <Divider />
            <s.AlertContainer>
                <Alert
                    type="info"
                    message="If you want to whitelist a new company for signup, please create a new HubSpot company"
                />
            </s.AlertContainer>
            <Divider />
        </>
    )
}

export default WhitelistExistent
