import ky from 'ky'

import config from 'config'

import {hooks} from './request-hooks'

const endpoints = config.endpoints

let request = ky.extend({hooks})

export const setRequestToken = (adminToken: string) => {
    request = ky.extend({
        timeout: 60000,
        headers: {
            Authorization: `Bearer ${adminToken}`
        },
        hooks: hooks
    })
}

// This is more correct since we're going through the API gateway, and in most cases deimos, to reach services
export const apiGatewayRequest = () => request.extend({prefixUrl: endpoints.api})
export const uranusRequest = () => request.extend({prefixUrl: endpoints.uranus})
export const kerberosRequest = () => request.extend({prefixUrl: endpoints.auth})
export const hydraRequest = () => request.extend({prefixUrl: endpoints.hydra})
export const dionysusRequest = () =>
    request.extend({
        prefixUrl: endpoints.dionysus,
        // Requests to generate demo accounts can take over 2mins
        timeout: 180000
    })
