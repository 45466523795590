import useSWR from 'swr'
import {getKerberos, postKerberos, deleteKerberos} from 'services/kerberos'
import {WhitelistOptions, Whitelist} from 'types/whitelist'

export function createWhitelist(emailPattern: string, options: WhitelistOptions = {}) {
    return postKerberos('api/v1/admin/whitelist', {
        emailPattern,
        ...options,
        associatedCompanyId: options.hubspotCompanyId
    })
}

export function destroyWhitelist(whitelistId: string) {
    return deleteKerberos(`api/v1/admin/whitelist/${whitelistId}`)
}

export function useWhitelists(companyId?: string) {
    const result = useSWR<Whitelist[]>(
        companyId
            ? `api/v1/admin/whitelist?dataLabel=associatedCompanyId&dataValue=${companyId}`
            : '',
        getKerberos
    )

    const updateWhitelists = async (emailPattern: string, options: WhitelistOptions = {}) => {
        await createWhitelist(emailPattern, options)
        return result.mutate()
    }

    const deleteWhitelists = async () => {
        result.data?.forEach(async (whitelist) => {
            await destroyWhitelist(whitelist.id)
            return result.mutate()
        })
    }
    return {...result, mutations: {updateWhitelists, deleteWhitelists}}
}

export function useWhitelistsByEmail(email?: string) {
    const result = useSWR<Whitelist[]>(
        email ? `api/v1/admin/whitelist?emailPattern=${encodeURIComponent(email)}` : '',
        getKerberos
    )

    const deleteWhitelists = async () => {
        result.data?.forEach(async (whitelist) => {
            await destroyWhitelist(whitelist.id)
            return result.mutate()
        })
    }

    return {...result, mutations: {deleteWhitelists}}
}
