import React, {FC} from 'react'
import styled from 'styled-components'
import {Typography, Divider, Button, Skeleton, notification} from 'antd'
import {
    Company,
    Velocity,
    KycStatus,
    KycInformation,
    Document,
    CompanyStakeholderResponse
} from 'types/kyc-overview'
import MissingInformation from './missing-information'
import KycTable from './kyc-table'
import moment from 'moment'
import {
    customerAgreedWithPricing,
    useCompany,
    useStyxCompany,
    useOberonStorageWithCompanyId,
    useCompanyMissingInformation,
    useCompanyDocuments,
    useCompanyManagement
} from 'services/company'
import {useParams} from 'react-router-dom'
import {NoStyxId} from 'components/page-message'
import {getRegistryId} from 'utils'
import {useKycState} from 'providers/token-provider'
import ErrorBoundary from 'providers/error-boundary'
import HasAmlAnswersContainer from './has-aml-answers'

import {InfoCircleOutlined} from '@ant-design/icons'
import {Tooltip} from 'antd'

const {Text} = Typography

interface CompanyDetailsProps {
    company: Company
    velocity?: Velocity
    hasAmlAnswers: React.ReactNode
    lastUpdatedKyc?: string
    handleCustomerAgreedWithPricing: () => void
    showCustomerPricingButton: boolean
    customerAlreadyConfirmedPricing: boolean
}

export const CompanyDetailsSection: FC<
    React.PropsWithChildren<React.PropsWithChildren<CompanyDetailsProps>>
> = ({
    company,
    velocity,
    hasAmlAnswers,
    lastUpdatedKyc,
    handleCustomerAgreedWithPricing,
    showCustomerPricingButton,
    customerAlreadyConfirmedPricing
}) => (
    <CompanyDetailsContainer>
        <InfoColumn>
            <Text strong>Company</Text>
            <Text>{company.name}</Text>
        </InfoColumn>
        <InfoColumn>
            <Text strong>Pleo ID</Text>
            <Text copyable>{company.id}</Text>
        </InfoColumn>
        <InfoColumn>
            <Text strong>Registry ID</Text>
            <Text>{getRegistryId(company.address?.country, company.registrationNumber)}</Text>
        </InfoColumn>
        <InfoColumn>
            <Text strong>Velocity</Text>
            <Text>{velocity}</Text>
        </InfoColumn>
        <InfoColumn>
            <Text strong>Created</Text>
            <Text>{moment(company.createdAt).format('lll')}</Text>
        </InfoColumn>
        <InfoColumn>
            <Text strong>Has AML answers</Text>
            {hasAmlAnswers}
        </InfoColumn>
        {lastUpdatedKyc && (
            <InfoColumn>
                <Text strong>Last submitted KYC info</Text>
                <Text>{moment(parseInt(lastUpdatedKyc)).format('lll')}</Text>
            </InfoColumn>
        )}
        {showCustomerPricingButton && (
            <InfoColumn>
                <CustomerPricingWrapper>
                    <TooltipWrapper>
                        <Tooltip
                            placement="bottomLeft"
                            title={
                                customerAlreadyConfirmedPricing
                                    ? 'Customer already confirmed pricing. CDD process has been triggered.'
                                    : 'Triggers the CDD process IF the user has already completed the company verification flow, otherwise, CDD process will be triggered as usual by the user completing the flow. This unblocks the CDD process'
                            }
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </TooltipWrapper>
                    <CustomerPricingButton
                        onClick={handleCustomerAgreedWithPricing}
                        disabled={customerAlreadyConfirmedPricing}
                    >
                        <>
                            {' '}
                            Mark pricing as <br /> agreed with customer
                        </>
                    </CustomerPricingButton>
                </CustomerPricingWrapper>
            </InfoColumn>
        )}
    </CompanyDetailsContainer>
)

interface KycOverviewProps {
    company?: Company
    styxCompany?: Company
    missingCompanyInfo?: KycInformation[]
    companyDocuments?: Document[]
    management?: CompanyStakeholderResponse[]
    isValidating: boolean
    hasAmlAnswers: React.ReactNode
    lastUpdatedKyc?: string
    handleCustomerAgreedWithPricing: () => void
    showCustomerPricingButton: boolean
    customerAlreadyConfirmedPricing: boolean
}

export const KycOverviewSection: FC<
    React.PropsWithChildren<React.PropsWithChildren<KycOverviewProps>>
> = ({
    company,
    styxCompany,
    missingCompanyInfo,
    companyDocuments,
    management,
    isValidating,
    hasAmlAnswers,
    lastUpdatedKyc,
    handleCustomerAgreedWithPricing,
    showCustomerPricingButton,
    customerAlreadyConfirmedPricing
}) => {
    if (!company && isValidating)
        return (
            <ContentSkeleton data-testid="skeleton">
                <Skeleton />
            </ContentSkeleton>
        )
    if (!company) {
        throw new Error('Not Found')
    }
    if (!company.styxId) {
        return <NoStyxId />
    }
    const velocity = sortKycChecksDescending(styxCompany?.kycCheckpoints)?.[0]?.level as
        | Velocity
        | undefined

    return (
        <>
            <CompanyDetailsSection
                company={company}
                velocity={velocity}
                hasAmlAnswers={hasAmlAnswers}
                lastUpdatedKyc={lastUpdatedKyc}
                handleCustomerAgreedWithPricing={handleCustomerAgreedWithPricing}
                showCustomerPricingButton={showCustomerPricingButton}
                customerAlreadyConfirmedPricing={customerAlreadyConfirmedPricing}
            />
            <Divider style={{margin: 0}} />
            <MissingInformation
                missingInformation={missingCompanyInfo || []}
                companyDocuments={companyDocuments || []}
                managers={management || []}
            />
            <KycTable missingInformation={missingCompanyInfo || []} managers={management || []} />
        </>
    )
}

const KycOverviewContainer: FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const {id: deimosCompanyId} = useParams<{id: string}>()
    const [api, contextHolder] = notification.useNotification()
    const [kyc]: any = useKycState({lastUpdatedKyc: ''})

    const {data: company} = useCompany(deimosCompanyId)

    const {data: styxCompany, isValidating} = useStyxCompany(deimosCompanyId || '')
    const {data: missingCompanyInfo} = useCompanyMissingInformation(deimosCompanyId)
    const {data: companyDocuments} = useCompanyDocuments(deimosCompanyId)
    const {data: management} = useCompanyManagement(deimosCompanyId)

    const {data: isFromMultiEntitySignUp} = useOberonStorageWithCompanyId(
        'from-multi-entity-sign-up-flow',
        deimosCompanyId ?? ''
    )

    const {data: multiEntityCompanyBilling} = useOberonStorageWithCompanyId(
        'multi-entity-company-billing',
        deimosCompanyId ?? ''
    )

    const velocity = sortKycChecksDescending(styxCompany?.kycCheckpoints)?.[0]?.level ?? ''

    const showCustomerPricingButton =
        isFromMultiEntitySignUp?.value === 'true' &&
        (velocity === Velocity.SDD || velocity === Velocity.UNSET)

    const customerAlreadyConfirmedPricing = multiEntityCompanyBilling?.value === 'true'

    const openNotification = (isSuccessful: boolean) => {
        api[isSuccessful ? 'success' : 'error']({
            message: isSuccessful ? 'Success' : 'Something went wrong',
            description: isSuccessful
                ? "You've successfully marked the pricing as agreed with the customer."
                : 'Please check if you have the right account persmissions. If the problem persists, please contact Team Manage Entities.',
            placement: 'topRight',
            duration: 5,
            type: isSuccessful ? 'success' : 'error'
        })
    }

    const handleCustomerAgreedWithPricing = async () => {
        try {
            await customerAgreedWithPricing(deimosCompanyId ?? '')
            openNotification(true)
        } catch (e) {
            openNotification(false)
        }
    }

    return (
        <>
            {contextHolder}
            <KycOverviewSection
                styxCompany={styxCompany}
                missingCompanyInfo={missingCompanyInfo}
                companyDocuments={companyDocuments}
                management={management}
                company={company}
                isValidating={isValidating}
                hasAmlAnswers={<HasAmlAnswersContainer deimosCompanyId={deimosCompanyId || ''} />}
                lastUpdatedKyc={kyc?.lastUpdatedKyc}
                showCustomerPricingButton={showCustomerPricingButton}
                customerAlreadyConfirmedPricing={customerAlreadyConfirmedPricing}
                handleCustomerAgreedWithPricing={() => handleCustomerAgreedWithPricing()}
            />
        </>
    )
}

const KycOverviewContainerWithBoundary: FC<
    React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = () => (
    <ErrorBoundary info="Company was deleted">
        <KycOverviewContainer />
    </ErrorBoundary>
)

export default KycOverviewContainerWithBoundary

const InfoColumn = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
`
const CompanyDetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 35px;
`
const ContentSkeleton = styled.span``

const CustomerPricingWrapper = styled.div`
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    height: fit-content;
`
const TooltipWrapper = styled.div`
    margin: auto 5px;
`

const CustomerPricingButton = styled(Button)`
    height: fit-content;
    text-align: left;
    display: flex;
    align-items: center;
`

export const sortKycChecksDescending = (checks: KycStatus[] | undefined) =>
    checks
        ? checks.sort((a, b) => moment(b.performed).valueOf() - moment(a.performed).valueOf())
        : []
