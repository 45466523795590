import React, {FC, useState} from 'react'
import {Checkbox, Button} from 'antd'
import {ButtonContainer} from './button-container'
import {Container} from 'pages/whitelist/whitelist.styles'

interface Props {
    plural?: boolean
    disabled?: boolean
    checkBoxMessage: string
    removeConnections: () => void
}
const RemoveConnection: FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    plural,
    checkBoxMessage,
    disabled,
    removeConnections
}) => {
    const [accepted, setAccepted] = useState(false)
    return (
        <Container>
            <Checkbox checked={accepted} onChange={(event) => setAccepted(event.target.checked)}>
                {checkBoxMessage}
            </Checkbox>
            <ButtonContainer>
                <Button type="primary" disabled={!accepted || disabled} onClick={removeConnections}>
                    Remove connection{plural ? 's' : ''}
                </Button>
            </ButtonContainer>
        </Container>
    )
}

export default RemoveConnection
