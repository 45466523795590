import {Amount, PageOrder} from '@pleo-io/deimos'
import {PaginationRequest} from 'utils/use-cursor-pagination'
import {SupportedCountry} from './countries'
import {Velocity} from './kyc-overview'
import {SupportedLanguage} from './supported-language'

interface CustomExpenseDetails {
    merchantName: string
    // Must be the currency of the country of company being created
    currency: string
    amount: number
    date: string
}

export enum CreateDemoAccountType {
    BASIC = 'Basic',
    EXTENDED = 'Extended'
}

export interface GetPrecreatedDemoAccountRequest {
    country: SupportedCountry
    companyName: string
    requesterEmail?: string
}

export interface CreateDemoAccountRequest {
    country: SupportedCountry
    companyName: string
    initialWalletLoadAmount: number
    expenses?: {
        randomExpenseCount?: number
        customExpenseDetails?: CustomExpenseDetails[]
        expensePreset: {
            country: SupportedCountry
        }
    }
    memberEmployeesCount?: Number
    language: SupportedLanguage
    requesterEmail?: string
    expiresAt?: string | null
}

export type Role = 'OWNER' | 'MEMBER'

export interface DemoAccountModel {
    id: string
    companyId: string
    requesterEmail?: string
    expiresAt?: string
    createdAt: string
    updatedAt: string
    deletedAt?: string
    name: string
}

const sortableProperties = ['CREATED_AT', 'UPDATED_AT', 'EXPIRES_AT'] as const

export type SortableDemoAccountProperty = (typeof sortableProperties)[number]

export type Sorting = [Record<SortableDemoAccountProperty, PageOrder>]

export function toDemoAccountsSorting(property: SortableDemoAccountProperty, pageOrder: PageOrder) {
    return [{[property]: pageOrder}] as Sorting
}

export type DemoAccountPaginationRequest = PaginationRequest<SortableDemoAccountProperty>

interface CompanyResponse {
    id: string
    name: string
    country: SupportedCountry
    velocity?: Velocity
    balance?: Amount
}

export interface EmployeeResponse {
    role: Role
    email: string
    passcode: string
}

interface ExpensesResponse {
    createdExpenseIds: string[]
    totalCreated: number
}
export interface DemoAccountResponse {
    company: CompanyResponse
    employees: EmployeeResponse[]
    expiresAt?: string
    expenses: ExpensesResponse
}
