import {AfterResponseHook, BeforeRequestHook, Hooks} from 'ky'
import {getExistingInterceptors, normalizeHTTPMethod} from './request-interceptor'

export const getServerErrorResponse: AfterResponseHook = async (_request, _options, response) => {
    // Return early on responses with status 204 as calling .json() will throw an unwanted error
    if (response.status === 204) {
        return
    }

    const res = await response.json()
    if (res.error || response.status === 400 || response.status === 500) {
        const message = res.meta?.message ?? res.message
        throw Error(message)
    }
}

const mockResponseInterceptor: BeforeRequestHook = async (request: Request) => {
    const method = normalizeHTTPMethod(request.method)
    const existingInterceptors = getExistingInterceptors(method, request.url)

    if (existingInterceptors?.length) {
        const nextResponse = existingInterceptors[0]
        existingInterceptors.shift()

        return nextResponse
    }
}

const beforeRequest: BeforeRequestHook[] = []

// ! We use this function in setupTest.ts to ensure response interception is only enabled when running tests
export const enableMockResponseInterception = () => {
    beforeRequest.push(mockResponseInterceptor)
}

export const hooks: Hooks = {
    beforeRequest,
    afterResponse: [getServerErrorResponse]
}
