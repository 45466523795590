import {Tag} from 'antd'
import React from 'react'

export const getTagStatusComponent = (status: string) => {
    function getTagColor() {
        switch (status) {
            case 'OPEN':
                return 'gold'
            case 'PAID':
                return 'green'
            case 'CREDITED':
            case 'PARTIALLY CREDITED':
                return 'lime'
            case 'DRAFT':
            default:
                return 'grey'
        }
    }
    return <Tag color={getTagColor()}>{status}</Tag>
}
