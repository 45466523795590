import React from 'react'
import {
    Form,
    Input,
    Typography,
    Button,
    Space,
    Modal,
    Select,
    Checkbox,
    message,
    Result
} from 'antd'
import {ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import {useCompany, useCompanies, CompanyWithOrganizationId} from 'services/company'

import {
    useCompanyRegistrySearch,
    useSupportedCompanyRegistrationLocales
} from 'services/company-registry'
import {GetCompanySearchResponse} from '@pleo-io/deimos'
import {
    createOrganizationGroupingRequest,
    OrganizationGroupingRequest
} from 'services/organizations'
import styled from 'styled-components'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'
import {
    ConfirmationModalDataType,
    FormType,
    getConfirmationModalData,
    getConfirmationModalTitle,
    hasPendingOrganizationRequestAction,
    pleoIdPattern,
    useCompanyGroupingData
} from './utils'
import ConfirmModalContent from './confirm-modal'
import {Link} from 'react-router-dom'

const {Text} = Typography
const {confirm} = Modal

export const CreateCompanyGroupingForm = () => {
    const {company} = useCompanyGroupingData()
    const [companiesQuery, setCompaniesQuery] = React.useState('')
    const {data: companies} = useCompanies(companiesQuery)

    const [companyIdQuery, setCompanyIdQuery] = React.useState('')
    const {data: companyById} = useCompany(companyIdQuery)
    const {data: supportedLocales} = useSupportedCompanyRegistrationLocales()

    const companiesData = React.useMemo(
        () => Array.from(new Set([...(companies ?? []), ...(companyById ? [companyById] : [])])),
        [companies, companyById]
    )

    const {
        companies: styxCompanies,
        setCountryCode,
        setQuery: setStyxQuery
    } = useCompanyRegistrySearch()

    return (
        <CompanyGrouping
            currentCompany={company}
            companies={companiesData}
            styxCompanies={styxCompanies}
            setCountryCode={setCountryCode}
            setStyxQuery={setStyxQuery}
            supportedLocales={supportedLocales}
            onCompanyNameSearch={setCompaniesQuery}
            onCompanyIdSearch={setCompanyIdQuery}
        />
    )
}

const CompanyGrouping = ({
    currentCompany,
    companies,
    styxCompanies,
    setCountryCode,
    setStyxQuery,
    onCompanyNameSearch,
    onCompanyIdSearch,
    supportedLocales
}: {
    currentCompany?: CompanyWithOrganizationId
    companies?: CompanyWithOrganizationId[]
    supportedLocales?: string[]
    styxCompanies?: GetCompanySearchResponse[]
    setCountryCode: (query: string) => void
    setStyxQuery: (query: string) => void
    onCompanyNameSearch: (query: string) => void
    onCompanyIdSearch: (query: string) => void
}) => {
    const [isSuccess, setIsSuccess] = React.useState(false)
    const [form] = Form.useForm()
    const [customerConsent, setCustomerConsent] = React.useState(false)

    const companyOptions = (companies ?? [])
        .filter((company) => !company?.organizationId)
        .map((company) => ({
            value: company.id,
            label: company.name
        }))

    const onSearchExistingEntity = (query: string) => {
        if (query.match(pleoIdPattern)) {
            onCompanyIdSearch(query)
        } else {
            onCompanyNameSearch(query)
        }
    }

    const onSetCountryCode = (value: string) => {
        setCountryCode(value)
    }

    const onSearchStyxEntity = (query: string) => {
        // We want to initalise searching the company here as well to be able to check if there is an existing company with a matching registration number
        onCompanyNameSearch(query)
        setStyxQuery(query)
    }

    const onFinish = (values: FormType) => {
        const {confirmationModalData, groupingRequestData} = getConfirmationModalData({
            values
        })
        showConfirm(confirmationModalData, groupingRequestData)
    }

    const showConfirm = (
        confirmationModalData: ConfirmationModalDataType,
        groupingRequestData: OrganizationGroupingRequest
    ) => {
        confirm({
            title: getConfirmationModalTitle(confirmationModalData),
            icon: <ExclamationCircleOutlined />,
            width: '1000px',
            content: <ConfirmModalContent confirmationModalData={confirmationModalData} />,
            onOk: async () => {
                try {
                    await createOrganizationGroupingRequest(groupingRequestData)
                    setIsSuccess(true)
                    message.success('A task was created and compliance will review this request.')
                } catch (error) {
                    message.error(
                        'There was an error creating this grouping task. Please refresh the page and try again.'
                    )
                }
            },
            onCancel() {
                setCustomerConsent(false)
            }
        })
    }

    const onCheckbox = (e: CheckboxChangeEvent) => {
        setCustomerConsent(e.target.checked)
    }

    return (
        <div>
            {isSuccess && (
                <Result
                    status="success"
                    title={`Successfully created your grouping request`}
                    extra={[
                        <Button type="primary" key="requests">
                            <Link to="../../requests">View requests</Link>
                        </Button>
                    ]}
                />
            )}
            {!isSuccess && (
                <Form
                    labelCol={{span: 24}}
                    form={form}
                    onFinish={onFinish}
                    requiredMark
                    scrollToFirstError
                    initialValues={{
                        companyIdsToAdd: [{companyIdToAdd: undefined}]
                    }}
                >
                    <Form.Item name="newOrganizationName" label="Group name">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        required
                        label="Add a registered Pleo company to this group"
                        tooltip="Use this to add companies already registered in Pleo to this company grouping."
                    >
                        <Form.List
                            name="companyIdsToAdd"
                            rules={[
                                {
                                    validator: async (_, companyIdsToAdd) => {
                                        if (companyIdsToAdd.length < 1) {
                                            return Promise.reject(
                                                new Error(
                                                    'Add at least one company that already exists in Pleo when creating a new Organization'
                                                )
                                            )
                                        }
                                    }
                                }
                            ]}
                        >
                            {(fields, {add, remove}, {errors}) => (
                                <>
                                    {fields.map(({key, name, ...restField}) => (
                                        <ExistingEntitySearchWrapper
                                            key={key}
                                            style={{display: 'flex', marginBottom: 8}}
                                            align="baseline"
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'companyIdToAdd']}
                                                validateTrigger={['onChange']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please add at least one existing Pleo company'
                                                    },
                                                    {
                                                        validator: async (
                                                            _,
                                                            {value: companyId} = {}
                                                        ) => {
                                                            const {companyIdsToAdd = []} =
                                                                form.getFieldsValue()
                                                            const values =
                                                                companyIdsToAdd.map(
                                                                    ({companyIdToAdd}: any) =>
                                                                        companyIdToAdd?.value
                                                                ) ?? []
                                                            const mappedValues = values.reduce(
                                                                (acc: any, e: any) =>
                                                                    acc.set(
                                                                        e,
                                                                        (acc.get(e) || 0) + 1
                                                                    ),
                                                                new Map()
                                                            )

                                                            if (
                                                                !companyId ||
                                                                mappedValues.get(companyId) === 1
                                                            ) {
                                                                return Promise.resolve()
                                                            }
                                                            return Promise.reject(
                                                                new Error(
                                                                    "The company you selected to add to this group is a duplicate. Please add another that you haven't already selected"
                                                                )
                                                            )
                                                        }
                                                    },

                                                    {
                                                        validator: async (
                                                            _,
                                                            {value: companyId} = {}
                                                        ) => {
                                                            if (!companyId) {
                                                                return Promise.resolve()
                                                            }

                                                            const hasPendingActions =
                                                                await hasPendingOrganizationRequestAction(
                                                                    {companyId}
                                                                )
                                                            if (!hasPendingActions) {
                                                                return Promise.resolve()
                                                            }

                                                            return Promise.reject(
                                                                new Error(
                                                                    'The company you selected to add to this group has a pending request action. Please add another without a pending action'
                                                                )
                                                            )
                                                        }
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    autoClearSearchValue={false}
                                                    allowClear
                                                    filterOption={(input, option) => {
                                                        return (option?.label ?? '')
                                                            .toString()
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }}
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '')
                                                            .toString()
                                                            .toLowerCase()
                                                            .localeCompare(
                                                                (optionB?.label ?? '')
                                                                    .toString()
                                                                    .toLowerCase()
                                                            )
                                                    }
                                                    onSearch={onSearchExistingEntity}
                                                    placeholder="Look up company by name in Pleo"
                                                    showArrow={false}
                                                    showSearch
                                                    labelInValue
                                                >
                                                    <Select.OptGroup label="Company">
                                                        {companyOptions.map((company) => (
                                                            <Select.Option
                                                                key={company.value}
                                                                value={company.value}
                                                                label={company.label}
                                                            >
                                                                {company.label}{' '}
                                                                <Text type="secondary">
                                                                    ({company.value})
                                                                </Text>
                                                            </Select.Option>
                                                        ))}
                                                    </Select.OptGroup>
                                                </Select>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </ExistingEntitySearchWrapper>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add existing Pleo company
                                        </Button>
                                    </Form.Item>
                                    <Form.ErrorList errors={errors} />
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item
                        label="Sign up a new company to Pleo and add to this group"
                        tooltip="This is to onboard a new entity that is not yet using Pleo. To use this, select the country code and search by company name. Ensure the registration number matches the one the customer provided you with."
                    >
                        <Form.List name="registryIdsToCreate">
                            {(fields, {add, remove}, {errors}) => (
                                <>
                                    {fields.map(({key, name, ...restField}) => (
                                        <StyxSearchWrapper
                                            key={key}
                                            style={{display: 'flex', marginBottom: 8}}
                                            align="baseline"
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'baseline'
                                                }}
                                            >
                                                <Select
                                                    style={{
                                                        width: 150
                                                    }}
                                                    placeholder="i.e GB"
                                                    onSelect={onSetCountryCode}
                                                >
                                                    {supportedLocales?.map((country) => (
                                                        <Select.Option
                                                            key={country}
                                                            value={country}
                                                        >
                                                            {country}
                                                        </Select.Option>
                                                    ))}
                                                </Select>{' '}
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'registryIdToCreate']}
                                                    style={{width: '100%'}}
                                                    validateTrigger={['onChange']}
                                                    rules={[
                                                        {
                                                            validator: async (
                                                                _,
                                                                {value: registryId} = {}
                                                            ) => {
                                                                if (!registryId) {
                                                                    return Promise.resolve()
                                                                }
                                                                const {registryIdsToCreate = []} =
                                                                    form.getFieldsValue()
                                                                const values =
                                                                    registryIdsToCreate.map(
                                                                        ({
                                                                            registryIdToCreate
                                                                        }: any) =>
                                                                            registryIdToCreate?.value
                                                                    )
                                                                const mappedValues = values.reduce(
                                                                    (acc: any, e: any) =>
                                                                        acc.set(
                                                                            e,
                                                                            (acc.get(e) || 0) + 1
                                                                        ),
                                                                    new Map()
                                                                )
                                                                if (
                                                                    mappedValues.get(registryId) ===
                                                                    1
                                                                ) {
                                                                    return Promise.resolve()
                                                                }
                                                                return Promise.reject(
                                                                    new Error(
                                                                        "The company you selected to sign up and add to this group is a duplicate. Please add another that you haven't already selected"
                                                                    )
                                                                )
                                                            }
                                                        },
                                                        {
                                                            validator: async (
                                                                _,
                                                                {value: registryId} = {}
                                                            ) => {
                                                                if (!registryId) {
                                                                    return Promise.resolve()
                                                                }

                                                                const hasPendingActions =
                                                                    await hasPendingOrganizationRequestAction(
                                                                        {registryId}
                                                                    )
                                                                if (!hasPendingActions) {
                                                                    return Promise.resolve()
                                                                }

                                                                return Promise.reject(
                                                                    new Error(
                                                                        'The company you selected to sign up and add to this group has a pending request action. Please add another without a pending action'
                                                                    )
                                                                )
                                                            }
                                                        },
                                                        {
                                                            validator: async (
                                                                _,
                                                                {value: registryId} = {}
                                                            ) => {
                                                                if (!registryId) {
                                                                    return Promise.resolve()
                                                                }

                                                                if (
                                                                    !companies?.find(
                                                                        (company) =>
                                                                            company?.registrationNumber?.includes(
                                                                                registryId
                                                                            )
                                                                    )
                                                                ) {
                                                                    return Promise.resolve()
                                                                }

                                                                return Promise.reject(
                                                                    new Error(
                                                                        'The company you selected to sign up already exists in Pleo. Please add that existing company instead of trying to add sign up a new one'
                                                                    )
                                                                )
                                                            }
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        autoClearSearchValue={false}
                                                        filterOption={false}
                                                        onSearch={onSearchStyxEntity}
                                                        placeholder="Look up company by name or registration number in government registry"
                                                        showArrow={false}
                                                        showSearch
                                                        labelInValue
                                                    >
                                                        <Select.OptGroup label="Company">
                                                            {styxCompanies
                                                                ?.slice(0, 5)
                                                                .map(
                                                                    (
                                                                        company: GetCompanySearchResponse
                                                                    ) => (
                                                                        <Select.Option
                                                                            key={company.registryId}
                                                                            value={
                                                                                company.registryId
                                                                            }
                                                                            label={
                                                                                company.legalName
                                                                            }
                                                                        >
                                                                            {company.legalName}{' '}
                                                                            <Text type="secondary">
                                                                                (
                                                                                {company.registryId}
                                                                                )
                                                                            </Text>
                                                                        </Select.Option>
                                                                    )
                                                                )}
                                                        </Select.OptGroup>
                                                    </Select>
                                                </Form.Item>
                                                <Form.ErrorList errors={errors} />
                                                <MinusCircleOutlined
                                                    style={{marginLeft: '6px'}}
                                                    onClick={() => remove(name)}
                                                />
                                            </div>
                                        </StyxSearchWrapper>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Sign up new company
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item
                        required
                        label="Do you have the customer's consent to group these entities?"
                        valuePropName="checked"
                    >
                        <Checkbox checked={customerConsent} onChange={onCheckbox}>
                            Yes
                        </Checkbox>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" disabled={!customerConsent}>
                        Request group creation
                    </Button>
                </Form>
            )}
        </div>
    )
}

const ExistingEntitySearchWrapper = styled(Space)`
    .ant-space-item:first-child {
        width: 100%;
    }
`

const StyxSearchWrapper = styled(Space)`
    .ant-space-item:first-child {
        width: 100%;
    }
`
