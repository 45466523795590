import React from 'react'
import {Button, Result, Typography} from 'antd'
import config from 'config'

const SMS_OTP = config.auth.smsOtp

const {Text} = Typography

interface Props {
    email: string
    passcode: string
}

const AccountDetails = ({email, passcode}: Props) => {
    return (
        <Result
            status="success"
            title="Successfully created demo account"
            subTitle={
                <div>
                    The demo can be accessed at{' '}
                    <a href="https://app.staging.pleo.io" target="_blank" rel="noopener noreferrer">
                        app.staging.pleo.io
                    </a>{' '}
                    where you can login with the details below:
                </div>
            }
            extra={[
                <div key="email">
                    <Text>Email: </Text>
                    <Text type="secondary" copyable>
                        {email}
                    </Text>
                </div>,
                <div key="passcode">
                    <Text>Passcode: </Text>
                    <Text type="secondary" copyable>
                        {passcode}
                    </Text>
                </div>,
                <div key="smsOtp">
                    <Text>SMS One-time Password: </Text>
                    <Text type="secondary" copyable>
                        {SMS_OTP}
                    </Text>
                </div>,
                <br key="break" />,
                <Button type="primary" key="login-button">
                    <a href="https://app.staging.pleo.io" target="_blank" rel="noopener noreferrer">
                        Login to demo account
                    </a>
                </Button>
            ]}
        />
    )
}

export default AccountDetails
