import React from 'react'

import {Button, Layout, Skeleton, Empty, Menu, Typography, Tooltip} from 'antd'
import {DashboardOutlined, HomeOutlined, SearchOutlined} from '@ant-design/icons'
import {useCompanyGroupingData} from './utils'
import {Outlet, Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom'
import {RelatedByOrganization} from './related-by-organization'
import {CreateCompanyGroupingForm} from './create-new-company-grouping-form'
import {NavLink} from 'react-router-dom'
import {UpdateCompanyGroupingForm} from './update-company-grouping-form'
import {CompanyGroupingRequests} from './requests'
import ButtonGroup from 'antd/lib/button/button-group'
import {SearchContainer} from './search'

const CompanyGroupingApp = () => {
    const [collapsed, setCollapsed] = React.useState(false)
    const data = useCompanyGroupingData()
    const location = useLocation()

    const activeKey = React.useMemo(() => {
        if (!data?.company?.id && location.pathname.endsWith('requests')) {
            return '3'
        } else if (data?.company?.id && location.pathname.includes('search')) {
            return '2'
        } else {
            return '1'
        }
    }, [location.pathname, data?.company?.id])

    return (
        <Layout className="App" style={{minHeight: '100vh'}}>
            <Layout.Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
            >
                <Skeleton loading={data.isLoadingInitialData}>
                    {data.hasRelatedOrganization && (
                        <RelatedByOrganization
                            organizationId={data?.organization?.id}
                            treeSelectProps={{
                                defaultOpen: false,
                                value: data?.organization?.name
                            }}
                        />
                    )}
                </Skeleton>
                <Menu
                    theme="dark"
                    mode="inline"
                    activeKey={activeKey}
                    defaultSelectedKeys={[activeKey]}
                    items={[
                        {
                            key: '1',
                            icon: <HomeOutlined />,
                            label: (
                                <NavLink to={`${data?.company?.id}`} state={location.state}>
                                    Home
                                </NavLink>
                            )
                        },
                        {
                            key: '2',
                            icon: <SearchOutlined />,
                            label: (
                                <NavLink to={`${data?.company?.id}/search`} state={location.state}>
                                    Search
                                </NavLink>
                            )
                        },
                        {
                            key: '3',
                            icon: <DashboardOutlined />,
                            label: (
                                <NavLink
                                    to={`${data?.company?.id}/requests`}
                                    state={location.state}
                                >
                                    Your requests
                                </NavLink>
                            )
                        }
                    ]}
                />
            </Layout.Sider>
            <Layout>
                <Layout.Header
                    style={{
                        background: '#ececec'
                    }}
                >
                    {data?.company && (
                        <Typography.Paragraph>
                            Company ID:{' '}
                            <Tooltip title="This is the Pleo identifier of the company you're currently viewing in Hubspot. You can use it to look up a company in the back office, in metabase, etc.">
                                <Typography.Text copyable>{data.company.id}</Typography.Text>
                            </Tooltip>
                        </Typography.Paragraph>
                    )}
                </Layout.Header>
                <Layout.Content
                    style={{
                        padding: '0px 10px'
                    }}
                >
                    <Outlet />
                </Layout.Content>
                <Layout.Footer
                    style={{
                        background: '#eee'
                    }}
                >
                    <Typography.Paragraph>
                        This feature is still in active development :&#41;
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        Please check in with #team_admin_experience_huginn for updates.{' '}
                        <a
                            href="https://www.notion.so/FAQ-776077bc6cca402682aa5ed7377e6556"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Multi-entity FAQs
                        </a>
                    </Typography.Paragraph>
                </Layout.Footer>
            </Layout>
        </Layout>
    )
}

const CompanyGroupingContainer = () => {
    const data = useCompanyGroupingData()
    const {id: companyId} = useParams<{id: string}>()
    const location = useLocation()
    const navigate = useNavigate()
    const isHome = location.pathname.replace(/\/$/, '').endsWith(companyId ?? '')

    const showEmptyState = !!(!data.hasRelatedOrganization && data.company?.id && isHome)

    const showNotConnectedCompanyState = !data.company?.id && isHome

    return (
        <Skeleton loading={data.isLoadingInitialData}>
            {showEmptyState && (
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                        height: 60
                    }}
                    description={
                        <span>
                            <h2>
                                {data.company?.name} is not part of any company grouping. <br />{' '}
                                <a
                                    href="https://www.notion.so/FAQ-776077bc6cca402682aa5ed7377e6556"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Click here to read the multi-entity FAQs
                                </a>
                            </h2>
                        </span>
                    }
                >
                    <Button type="primary" onClick={() => navigate('grouping/create')}>
                        Create a new grouping
                    </Button>
                </Empty>
            )}
            {showNotConnectedCompanyState && (
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                        height: 60
                    }}
                    description={
                        <span>
                            <h2>
                                The current company you're viewing is not connected to any Pleo
                                company.{' '}
                            </h2>
                        </span>
                    }
                >
                    <p>
                        You need to{' '}
                        <a
                            href="https://www.notion.so/pleo/Connect-Company-4f45415e874e458ba9df062d67836bc9"
                            target="_blank"
                            rel="noreferrer"
                        >
                            link this Hubspot company to an existing Pleo company
                        </a>{' '}
                        to proceed with company grouping.
                    </p>
                </Empty>
            )}
            {data.company?.id &&
                data?.organization?.id &&
                !showNotConnectedCompanyState &&
                isHome && (
                    <Empty
                        description={
                            <Typography.Title level={2}>
                                {data?.company?.name} is part of the {data?.organization?.name}{' '}
                                group
                            </Typography.Title>
                        }
                    >
                        <ButtonGroup>
                            <Button
                                type="primary"
                                onClick={() => navigate(`grouping/${data.organization?.id}`)}
                            >
                                Update grouping
                            </Button>
                        </ButtonGroup>
                    </Empty>
                )}
            {!isHome && <Outlet />}
        </Skeleton>
    )
}

const CompanyGroupingLayout = () => {
    const data = useCompanyGroupingData()
    const location = useLocation()
    const navigate = useNavigate()
    const isGroupingRoute = location.pathname.includes('grouping')

    React.useEffect(() => {
        if (isGroupingRoute) {
            return
        }

        if (data?.organization?.id) {
            navigate(`grouping/${data?.organization?.id}`)
        }
    }, [data?.organization?.id, isGroupingRoute, navigate])

    return (
        <Skeleton loading={data.isLoadingInitialData}>
            <Outlet />
        </Skeleton>
    )
}

const CompanyGroupingPage = () => {
    return (
        <Routes>
            <Route path="/" element={<CompanyGroupingApp />}>
                <Route path=":id" element={<CompanyGroupingContainer />}>
                    <Route path="requests" element={<CompanyGroupingRequests />} />
                    <Route path="search" element={<SearchContainer />} />
                    <Route path="grouping" element={<CompanyGroupingLayout />}>
                        <Route path="create" element={<CreateCompanyGroupingForm />} />
                        <Route path=":organizationId" element={<UpdateCompanyGroupingForm />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    )
}

export default CompanyGroupingPage
