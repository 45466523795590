import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'

import {Organization} from '@pleo-io/deimos'
import {getOberon, postOberon} from './oberon'
import {TCountryCode} from 'countries-list'

type OrganizationRequestStatus = 'PENDING' | 'COMPLETED' | 'DECLINED'

type GroupingRequest = {
    type: 'ADD' | 'REMOVE' | 'NEW'
    id: string
    organizationName: string
    organizationId: string
    status: OrganizationRequestStatus
    createdAt: string
    updatedAt: string
}

type GetOrganizationGroupingRequestResponse = {
    data: GroupingRequest[]
    pagination: {
        hasPreviousPage: boolean
        hasNextPage: boolean
        currentRequestPagination: {
            limit: number
            parameters: {}
        }
        startCursor: string
        endCursor: string
    }
}

export type OrganizationRequest = {
    id: string
    type: 'ADD' | 'REMOVE' | 'NEW'
    organizationId: string
    organizationName: string
    companyId: string
    registryId: string
    status: OrganizationRequestStatus
    createdAt: string
    updatedAt: string
    mainContact?: string
    reviewerEmail?: string
}

type GetOrganizationRequestResponse = {
    data: OrganizationRequest[]
    pagination: {
        hasPreviousPage: boolean
        hasNextPage: boolean
        currentRequestPagination: {
            limit: number
            parameters: {}
        }
        startCursor: string
        endCursor: string
    }
}

export const getRequestCountUrl = () => ``

export interface OrganizationGroupingRequest {
    type: 'UPDATE' | 'CREATE' | 'DELETE'
    organizationName?: string
    organizationId?: string
    mainContact?: string
    companyIdsToAdd?: string[]
    companyIdsToRemove?: string[]
    registryIdsToCreate?: string[]
}

export interface OrganizationWithEntities {
    id: string
    name: string
    companies: Entity[]
}

export interface Entity {
    id: string
    companyId: string
    name: string
    address: {
        addressLine1: string
        locality: string
        postalCode: string
        country: string
    }
}

export type OrganizationEntity = {
    id: string
    companyId: string
    name: string
    address: {country: string | TCountryCode}
}

export type OrganizationResponse = {
    id: string
    name: string
    companies: Array<OrganizationEntity>
}

export const getQueryString = ({
    companyId,
    registryId,
    requesterEmail,
    statuses,
    limit,
    after
}: {
    limit?: number
    companyId?: string
    registryId?: string
    requesterEmail?: string
    after?: string
    statuses: OrganizationRequestStatus[]
}) => {
    const params = new URLSearchParams()

    if (registryId) {
        params.append('registryId', registryId)
    }

    if (companyId) {
        params.append('companyId', companyId)
    }

    if (requesterEmail) {
        params.append('requesterEmail', requesterEmail)
    }

    statuses.forEach((requestStatus) => {
        params.append('status', requestStatus)
    })

    if (limit) {
        params.append('limit', `${limit}`)
    }

    if (after) {
        params.append('after', after)
    }

    return params.toString()
}

export const getPendingOrganizationRequestAction = ({
    companyId,
    registryId
}: {
    companyId?: string
    registryId?: string
}) =>
    getOberon(
        `rest/v1/organization-grouping-request/actions?${getQueryString({
            companyId,
            registryId,
            statuses: ['PENDING'],
            limit: 1
        })}`
    )

const changeOrganizationName = (organizationId: string, name: string) =>
    postOberon(`rest/v1/admin/organization/${organizationId}/rename`, {name}).then((res) =>
        res.json()
    )

export const useOrganizations = (query: string) => {
    const {data: organizations} = useSWR<Organization[]>(
        `rest/v1/organizations/search?query=${query}`,
        getOberon,
        {revalidateOnFocus: false, suspense: false}
    )
    return {
        organizations
    }
}

export const useOrganizationById = (id?: string | null) => {
    const result = useSWR<OrganizationResponse>(
        id ? `rest/v1/organization/${id}` : null,
        getOberon,
        {
            revalidateOnFocus: false,
            suspense: false
        }
    )

    const renameOrganization = (name: string) => {
        if (name === result.data?.name || !result?.data?.id) {
            return
        }

        return changeOrganizationName(result.data?.id, name)
    }

    return {
        ...result,
        mutation: {
            renameOrganization
        }
    }
}

export const useOrganization = (id: string) => {
    const {data: organization} = useSWR<OrganizationWithEntities>(
        id ? `rest/v1/organization/${id}` : null,
        getOberon,
        {revalidateOnFocus: false, suspense: false}
    )
    return {
        organization
    }
}

export const createOrganizationGroupingRequest = async (
    organizationGroupingRequest: OrganizationGroupingRequest
): Promise<any> =>
    await postOberon('rest/v1/organization-grouping-request/', organizationGroupingRequest).then(
        (res) => res.json()
    )

export const useOrganizationRequestActionsCount = ({
    bypassHook = false,
    statuses,
    companyId,
    requesterEmail
}: {
    bypassHook?: boolean
    companyId?: string
    requesterEmail?: string
    statuses: OrganizationRequestStatus[]
}) => {
    const query = getQueryString({companyId, requesterEmail, statuses})

    return useSWR(
        bypassHook ? undefined : `rest/v1/organization-grouping-request/actions/count?${query}`,
        getOberon,
        {
            shouldRetryOnError: false,
            revalidateOnMount: true
        }
    )
}

export const usePendingOrganizationRequestActionsCount = ({
    bypassHook = false,
    companyId,
    requesterEmail
}: {
    bypassHook?: boolean
    companyId?: string
    requesterEmail?: string
}) => {
    return useOrganizationRequestActionsCount({
        bypassHook,
        companyId,
        requesterEmail,
        statuses: ['PENDING']
    })
}

export const useProcessedOrganizationRequestActionsCount = ({
    bypassHook = false,
    companyId,
    requesterEmail
}: {
    bypassHook?: boolean
    companyId?: string
    requesterEmail?: string
}) => {
    return useOrganizationRequestActionsCount({
        bypassHook,
        companyId,
        requesterEmail,
        statuses: ['COMPLETED', 'DECLINED']
    })
}

const PAGE_SIZE = 25

export const useOrganizationRequestActions = ({
    statuses,
    limit = PAGE_SIZE,
    companyId,
    requesterEmail,
    registryId,
    bypassHook = false
}: {
    limit?: number
    requesterEmail?: string
    companyId?: string
    registryId?: string
    statuses: OrganizationRequestStatus[]
    bypassHook?: boolean
}) => {
    const getKey = (_pageIndex: number, previousPageData: GetOrganizationRequestResponse) => {
        if (previousPageData && !previousPageData.data) return null
        const query = getQueryString({
            registryId,
            companyId,
            requesterEmail,
            statuses,
            limit: limit ?? previousPageData?.pagination.currentRequestPagination.limit,
            after: previousPageData?.pagination?.endCursor
        })

        return bypassHook ? null : `rest/v1/organization-grouping-request/actions?${query}`
    }

    const result = useSWRInfinite<GetOrganizationRequestResponse>(getKey, getOberon, {
        shouldRetryOnError: false,
        revalidateOnMount: true
    })

    const data = (result.data ?? []).map((d) => d.data).flat()
    const pagination = result.data?.[result?.data.length - 1].pagination
    const isLoadingInitialData = !result.data && !result.error
    const isLoadingMore = !!(
        result.size > 0 &&
        result.data &&
        typeof result.data[result.size - 1] === 'undefined'
    )

    const fetchNextPage = () => result.setSize?.((result.size ?? 0) + 1)
    const fetchPreviousPage = () => result.setSize?.((result.size ?? 0) - 1)
    const fetchFirstPage = () => result.setSize?.(1)

    return {
        ...result,
        data,
        pagination,
        isLoadingMore,
        isLoadingInitialData,
        fetchFirstPage,
        fetchNextPage,
        fetchPreviousPage
    }
}

const updateOrganizationRequestAction = (id: string, status: OrganizationRequestStatus) => {
    return postOberon(`rest/v1/organization-grouping-request/actions/${id}`, {
        json: {status}
    })
}

export const usePendingOrganizationRequestActions = ({
    limit,
    bypassHook = false,
    companyId,
    registryId,
    requesterEmail
}: {
    limit?: number
    companyId?: string
    registryId?: string
    requesterEmail?: string
    bypassHook?: boolean
}) => {
    const result = useOrganizationRequestActions({
        statuses: ['PENDING'],
        limit,
        requesterEmail,
        companyId,
        registryId,
        bypassHook
    })

    const approve = async (id: string) => {
        return updateOrganizationRequestAction(id, 'COMPLETED')
        // return mutate(ORGANIZATION_REQUEST_COUNT_URL)
    }

    const decline = async (id: string) => {
        return await updateOrganizationRequestAction(id, 'DECLINED')
        // return mutate(ORGANIZATION_REQUEST_COUNT_URL)
    }

    return {
        ...result,
        mutation: {
            approve,
            decline
        }
    }
}

export const useProcessedOrganizationRequestActions = ({
    bypassHook = false,
    limit,
    companyId,
    requesterEmail
}: {
    limit?: number
    companyId?: string
    requesterEmail?: string
    bypassHook?: boolean
}) => {
    return useOrganizationRequestActions({
        statuses: ['DECLINED', 'COMPLETED'],
        limit,
        companyId,
        requesterEmail,
        bypassHook
    })
}

type OrganizationGroupingRequestInfo = {
    type: string
    mainContact: string
}

type GetOrganizationGroupingRequestInfoResponse = {
    items: OrganizationGroupingRequestInfo[]
}

export const useOrganizationGroupingRequestsByOrganizationId = ({
    bypassHook = false,
    statuses,
    companyId,
    requesterEmail,
    organizationId
}: {
    bypassHook?: boolean
    companyId?: string
    requesterEmail?: string
    statuses: OrganizationRequestStatus[]
    organizationId: string
}) => {
    const query = getQueryString({companyId, requesterEmail, statuses})

    return useSWR<GetOrganizationGroupingRequestInfoResponse>(
        bypassHook ? undefined : `rest/v1/organization-grouping-request/${organizationId}?${query}`,
        getOberon,
        {
            shouldRetryOnError: false,
            revalidateOnMount: true
        }
    )
}

export const useOrganizationGroupingRequests = ({
    statuses,
    limit = PAGE_SIZE,
    companyId,
    requesterEmail,
    bypassHook = false
}: {
    limit?: number
    requesterEmail?: string
    companyId?: string
    statuses: OrganizationRequestStatus[]
    bypassHook?: boolean
}) => {
    const getKey = (_pageIndex: number, previousPageData: GetOrganizationRequestResponse) => {
        if (previousPageData && !previousPageData.data) return null
        const query = getQueryString({
            companyId,
            requesterEmail,
            statuses,
            limit: limit ?? previousPageData?.pagination.currentRequestPagination.limit,
            after: previousPageData?.pagination?.endCursor
        })

        return bypassHook ? null : `rest/v1/organization-grouping-request?${query}`
    }

    const result = useSWRInfinite<GetOrganizationGroupingRequestResponse>(getKey, getOberon, {
        shouldRetryOnError: false,
        revalidateOnMount: true
    })

    const data = (result.data ?? []).map((d) => d.data).flat()
    const pagination = result.data?.[result?.data.length - 1].pagination
    const isLoadingInitialData = !result.data && !result.error
    const isLoadingMore = !!(
        result.size > 0 &&
        result.data &&
        typeof result.data[result.size - 1] === 'undefined'
    )

    const fetchNextPage = () => result.setSize?.((result.size ?? 0) + 1)
    const fetchPreviousPage = () => result.setSize?.((result.size ?? 0) - 1)
    const fetchFirstPage = () => result.setSize?.(1)

    return {
        ...result,
        data,
        pagination,
        isLoadingMore,
        isLoadingInitialData,
        fetchFirstPage,
        fetchNextPage,
        fetchPreviousPage
    }
}
