import {CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons'
import {getColor} from 'theme/theme'
import styled from 'styled-components'

export const SuccessIcon = styled(CheckCircleFilled)`
    color: ${getColor('success')};
`

export const FailIcon = styled(CloseCircleFilled)`
    color: ${getColor('danger')};
`
