import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import Router from './router'
import TokenProvider from 'providers/token-provider'
import {theme} from 'theme/theme'
import {ThemeProvider} from 'styled-components'
import ErrorBoundary from 'providers/error-boundary'
import {SWRConfig} from 'swr'
import {Skeleton} from 'antd'
import {DatadogRUMProvider} from 'providers/datadog-rum'

import 'antd/dist/antd.css'

const App = () => (
    <SWRConfig value={{suspense: true}}>
        <Suspense fallback={<Skeleton />}>
            <ErrorBoundary>
                <TokenProvider>
                    <DatadogRUMProvider>
                        <ThemeProvider theme={theme}>
                            <Router />
                        </ThemeProvider>
                    </DatadogRUMProvider>
                </TokenProvider>
            </ErrorBoundary>
        </Suspense>
    </SWRConfig>
)

ReactDOM.render(<App />, document.getElementById('root'))
