import React, {useState} from 'react'

import {useCreateDemoAccount} from 'services/demo-accounts'
import {useUserEmail} from 'utils/use-user-email'

import GenerateDemoForm, {
    FormValuesBasic,
    FormValuesExtended,
    formValuesToCreateDemoAccountRequest,
    formValuesToGetPreCreatedDemoAccountRequest
} from './generate-demo-form/generate-demo-form'
import Loading from './loading-result/loading-result'
import ErrorResult from './error-result/error-result'
import AccountDetails from './account-details/account-details'
import {Radio} from 'antd'
import {CreateDemoAccountType} from 'types/demo-accounts'

const GenerateDemo = () => {
    const {
        accountDetails,
        loading,
        setCreateDemoAccountRequest,
        setCreatePreCreatedDemoAccountRequest,
        error,
        dismissError
    } = useCreateDemoAccount()

    const [createDemoAccountType, setCreateDemoAccountType] = useState<CreateDemoAccountType>(
        CreateDemoAccountType.BASIC
    )

    const requesterEmail = useUserEmail()
    const onSubmitExtended = (values: FormValuesExtended) => {
        const createDemoAccountRequest = formValuesToCreateDemoAccountRequest(
            values,
            requesterEmail
        )

        setCreateDemoAccountRequest(createDemoAccountRequest)
    }

    const onSubmitBasic = (values: FormValuesBasic) => {
        const createDemoAccountRequest = formValuesToGetPreCreatedDemoAccountRequest(
            values,
            requesterEmail
        )

        setCreatePreCreatedDemoAccountRequest(createDemoAccountRequest)
    }

    const goBack = () => {
        dismissError()
    }

    if (accountDetails) {
        return <AccountDetails email={accountDetails.email} passcode={accountDetails.passcode} />
    }

    if (error) {
        return <ErrorResult goBack={goBack} errorMessage={error.message} />
    }

    if (loading) {
        return <Loading />
    }
    return (
        <>
            <Radio.Group
                value={createDemoAccountType}
                onChange={(event) => setCreateDemoAccountType(event.target.value)}
            >
                <Radio.Button value={CreateDemoAccountType.BASIC}>Basic</Radio.Button>
                <Radio.Button value={CreateDemoAccountType.EXTENDED}>Extended</Radio.Button>
            </Radio.Group>

            <GenerateDemoForm
                createDemoAccountType={createDemoAccountType}
                onSubmitExtended={onSubmitExtended}
                onSubmitBasic={onSubmitBasic}
            />
        </>
    )
}

export default GenerateDemo
