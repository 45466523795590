import React from 'react'
import {InvalidToken, SomethingWentWrong} from 'components/page-message'

class ErrorBoundary extends React.Component<
    {info?: string; renderOnError?: React.ReactNode; children: React.ReactNode},
    {hasError: boolean; error: any}
> {
    constructor(props: any) {
        super(props)
        this.state = {
            hasError: false,
            error: null
        }
    }

    static getDerivedStateFromError = () => {
        return {hasError: true}
    }

    componentDidCatch = (error: any) => {
        this.setState({error})
    }

    render() {
        if (this.state.hasError) {
            if (this.props.renderOnError) {
                return this.props.renderOnError
            }
            switch (this.state.error?.message) {
                case 'Unauthorized':
                case 'no_user':
                    return <InvalidToken />
                default:
                    return (
                        <SomethingWentWrong
                            message={this.state.error?.message}
                            description={this.props.info}
                        />
                    )
            }
        }

        return this.props.children
    }
}

export default ErrorBoundary
