import {Typography} from 'antd'
import React from 'react'
import {ConfirmationModalDataType} from './utils'

const ConfirmModalContent = ({
    confirmationModalData: {
        organization,
        newOrganizationName,
        companiesToRemove,
        companiesToAdd,
        registryIdsToCreate,
        mainContact
    }
}: {
    confirmationModalData: ConfirmationModalDataType
}) => {
    const {Text} = Typography

    return (
        <div>
            {newOrganizationName && (
                <>
                    <h4>Organization (Name): </h4>
                    <Text>{newOrganizationName}</Text>
                </>
            )}

            {!newOrganizationName && organization && (
                <>
                    <h4>Organization (Name - Id): </h4>
                    <p>
                        <Text>{organization.label}</Text> -{' '}
                        <Text type="secondary">{organization.value}</Text>
                    </p>
                </>
            )}
            {companiesToRemove && companiesToRemove.length > 0 && (
                <>
                    <h4>Pleo companies that will be removed from this group: </h4>
                    <ul>
                        {companiesToRemove.map((company: {label: string; value: string}) => (
                            <li>
                                {company.label} - <Text type="secondary">{company.value}</Text>
                            </li>
                        ))}
                    </ul>
                </>
            )}

            {companiesToAdd && companiesToAdd.length > 0 && (
                <>
                    <h4>Pleo companies that will be added to this group: </h4>
                    <ul>
                        {companiesToAdd.map((company: {label: string; value: string}) => (
                            <li>
                                {company.label} - <Text type="secondary">{company.value}</Text>
                            </li>
                        ))}
                    </ul>
                </>
            )}

            {registryIdsToCreate && registryIdsToCreate.length > 0 && (
                <>
                    <h4>New companies that will be onboarded and added to this group: </h4>
                    <ul>
                        {registryIdsToCreate.map((company: {label: string; value: string}) => (
                            <li>
                                {company.label} - <Text type="secondary">{company.value}</Text>
                            </li>
                        ))}
                    </ul>
                </>
            )}
            <h4>Requester: </h4>
            <Text>{mainContact}</Text>
        </div>
    )
}

export default ConfirmModalContent
