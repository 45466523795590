import React, {FC} from 'react'
import {ConnectionDetails} from 'types/connect-company'
import {Alert, Divider, Typography} from 'antd'
import RemoveConnection from 'components/remove-connection'
import styled from 'styled-components'

const {Text} = Typography

interface ConnectionExistsProps {
    connectionDetails: ConnectionDetails
    removeConnections: () => void
}
const ConnectionExists: FC<
    React.PropsWithChildren<React.PropsWithChildren<ConnectionExistsProps>>
> = ({connectionDetails, removeConnections}) => {
    return (
        <>
            <Container>
                <Alert
                    type="warning"
                    message="This company is already connected to Pleo."
                    showIcon
                />
            </Container>
            <Divider />
            <Companies>
                <span>
                    <Text strong>Hubspot</Text>
                    <br />
                    <Text>Name: </Text>
                    <Text type="secondary">{connectionDetails.hubspotName}</Text>
                    <br />
                    <Text>ID: </Text>
                    <Text type="secondary" copyable>
                        {connectionDetails.hubspotId}
                    </Text>
                </span>
                <span>
                    <Text strong>Pleo</Text>
                    <br />
                    <Text>Name: </Text>
                    <Text type="secondary">{connectionDetails.pleoName}</Text>
                    <br />
                    <Text>ID: </Text>
                    <Text type="secondary" copyable>
                        {connectionDetails.pleoId}
                    </Text>
                </span>
            </Companies>
            <Divider />
            <RemoveConnection
                checkBoxMessage="I understand that removing this connection will reset the Pleo properties on the HubSpot Company information and undo the connection between Pleo and HubSpot"
                removeConnections={removeConnections}
            />
        </>
    )
}

export default ConnectionExists

const Companies = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 35px;
`
const Container = styled.div`
    padding-left: 35px;
`
