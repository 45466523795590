import {useState} from 'react'
import useSWR from 'swr'
import {getDeimos} from 'services/deimos'
import {GetCompanySearchResponse} from '@pleo-io/deimos'
import {useDebounce} from 'utils/debounce'
import {getStyx} from './styx'

export function useCompanyRegistrySearch() {
    const [countryCode, setCountryCode] = useState('GB')
    const [query, setQuery] = useState('')
    const debouncedQuery = useDebounce(query, 500)
    const {
        data: companies,
        error,
        isValidating
    } = useSWR<GetCompanySearchResponse[], Error>(
        countryCode && debouncedQuery.length > 1
            ? `rest/v1/registry/companies?c=${countryCode}&q=${debouncedQuery}`
            : '',
        getDeimos,
        {shouldRetryOnError: false, suspense: false}
    )
    return {companies, countryCode, error, isValidating, query, setCountryCode, setQuery}
}

export function useSupportedCompanyRegistrationLocales() {
    return useSWR<string[], Error>(
        `rest/v1/registry/countries?filterNoRegistrationLookUp=false`,
        getStyx,
        {shouldRetryOnError: false, suspense: false}
    )
}
