import React, {useMemo, useState} from 'react'
import {Select, Layout} from 'antd'
import {Organization} from '@pleo-io/deimos'
import {useOrganizations} from 'services/organizations'
import {RelatedByOrganization} from './related-by-organization'

export const SearchContainer = () => {
    const [organizationsQuery, onOrganizationNameSearch] = useState('')
    const [organizationId, setOrganizationId] = useState('')
    const {organizations} = useOrganizations(organizationsQuery)

    const options = useMemo(() => {
        return (organizations ?? []).slice(0, 5).map((organization: Organization) => ({
            label: organization.name,
            value: organization.id
        }))
    }, [organizations])

    return (
        <Layout>
            <Layout.Header
                style={{
                    background: '#f0f2f5',
                    padding: 0
                }}
            >
                <Select
                    style={{
                        width: '100%'
                    }}
                    autoClearSearchValue={false}
                    allowClear
                    filterOption={false}
                    onSearch={onOrganizationNameSearch}
                    onSelect={setOrganizationId}
                    placeholder="Search organizations by name to view structure"
                    showArrow={false}
                    showSearch
                    options={options}
                />
            </Layout.Header>
            <Layout.Content>
                {organizationId && (
                    <RelatedByOrganization
                        organizationId={organizationId}
                        treeSelectProps={{
                            bordered: false,
                            defaultOpen: true,
                            placeholder: 'Click to view org structure'
                        }}
                    />
                )}
            </Layout.Content>
        </Layout>
    )
}
