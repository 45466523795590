import useSWR from 'swr'
import {
    Company,
    CompanyStakeholderResponse,
    Document,
    KycInformation,
    KycInformationName
} from 'types/kyc-overview'
import {
    deleteDeimos,
    getDeimos,
    getDeimosIgnoreErrors,
    patchDeimos,
    postDeimos
} from 'services/deimos'
import {CompanyOffboardingStatus, InitiateOffboardingRequest} from '@pleo-io/deimos'
import {EntityNotFound} from 'types/general'
import {getOberon} from './oberon'
import {getStyx} from './styx'

export type CompanyWithOrganizationId = Company & {organizationId?: string | null}

export function useCompany(companyId?: string) {
    return useSWR<CompanyWithOrganizationId>(
        companyId ? `rest/v1/companies/${companyId}` : '',
        getDeimos,
        {
            revalidateOnFocus: false
        }
    )
}

export function useCompanies(query: string) {
    return useSWR<CompanyWithOrganizationId[]>(
        `rest/v1/admin/companies?query=${query}`,
        getDeimos,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            suspense: false
        }
    )
}

export function useStyxCompany(companyId?: string) {
    return useSWR<Company>(`rest/v2/styx-companies/${companyId}`, getStyx, {
        shouldRetryOnError: false,
        suspense: false
    })
}

export function useCompanyMissingInformation(companyId?: string) {
    return useSWR<KycInformation[]>(
        `rest/v2/styx-reviews/companies/${companyId}/missing-information`,
        getStyx,
        {shouldRetryOnError: false, suspense: false}
    )
}

export function useCompanyDocuments(companyId?: string) {
    return useSWR<Document[]>(`rest/v2/styx-companies/${companyId}/documents`, getStyx, {
        shouldRetryOnError: false,
        suspense: false
    })
}

export function useCompanyManagement(companyId?: string) {
    return useSWR<CompanyStakeholderResponse[]>(
        `rest/v2/styx-companies/${companyId}/management`,
        getStyx,
        {shouldRetryOnError: false, suspense: false}
    )
}

export function useHasAmlAnswers(companyId: string) {
    const result = useSWR(
        `rest/v2/styx-reviews/companies/${companyId}/missing-information?name=${KycInformationName.AML_ANSWERS}`,
        getStyx,
        {shouldRetryOnError: false, revalidateOnFocus: false}
    )
    const hasAmlAnswers = result.data === undefined ? undefined : result.data.length === 0
    return {hasAmlAnswers}
}

export const useOffboardingStatus = (companyId: string) => {
    const result = useSWR<CompanyOffboardingStatus | EntityNotFound>(
        `rest/v1/offboarding/companies/${companyId}/offboarding-status`,
        getDeimosIgnoreErrors,
        {
            shouldRetryOnError: false
        }
    )

    // The fetcher does not throw on 4xx-5xx responses, therefore we check the type and
    // return CompanyOffboardingStatus | undefined to keep this function inline with regular SWR functions
    const isResponseOffboardingStatus = (
        response?: CompanyOffboardingStatus | EntityNotFound
    ): response is CompanyOffboardingStatus => {
        return !!response && 'category' in response
    }

    const data = isResponseOffboardingStatus(result?.data) ? result.data : undefined

    return {...result, data}
}

export const initiateOrUpdateOffboarding = (
    companyId: string,
    request: InitiateOffboardingRequest
) => patchDeimos(`rest/v1/offboarding/companies/${companyId}/offboarding-status`, request)

export const deleteOffboarding = (companyId: string) =>
    deleteDeimos(`rest/v1/offboarding/companies/${companyId}/offboarding-status`).json()

export const customerAgreedWithPricing = (companyId: string) =>
    postDeimos(`rest/v2/grouping/companies/${companyId}?storageValue=true`)

export const useOberonStorageWithCompanyId = (key: string, companyId: string) => {
    return useSWR<{key: string; value?: 'true'}>(
        `rest/v1/storage-property/${key}?companyId=${companyId}`,
        getOberon
    )
}
