import {kerberosRequest} from 'services/request/handler'

export function getKerberos(route: string) {
    return kerberosRequest()
        .get(route)
        .then((res: Response) => res.json())
}

export function deleteKerberos(route: string) {
    return kerberosRequest().delete(route)
}

export function postKerberos(route: string, body?: Record<string, any>) {
    return kerberosRequest().post(route, {
        json: body
    })
}

export function patchKerberos(route: string, body?: Record<string, any>) {
    return kerberosRequest().patch(route, {
        json: body
    })
}
