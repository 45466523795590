import React, {FC, useState} from 'react'
import {Checkbox, Button} from 'antd'
import {NearbyButtonContainer} from './button-container'

interface Props {
    disabled?: boolean
    checkBoxMessage: string
    removeWhitelist: () => void
}
const RemoveWhitelist: FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    checkBoxMessage,
    disabled,
    removeWhitelist
}) => {
    const [accepted, setAccepted] = useState(false)
    return (
        <>
            <Checkbox checked={accepted} onChange={(event) => setAccepted(event.target.checked)}>
                {checkBoxMessage}
            </Checkbox>
            <NearbyButtonContainer>
                <Button type="primary" disabled={!accepted || disabled} onClick={removeWhitelist}>
                    Remove whitelist
                </Button>
            </NearbyButtonContainer>
        </>
    )
}

export default RemoveWhitelist
