import React, {FC, useEffect, useState} from 'react'
import {useCompanies, useCompany} from 'services/company'
import {Divider, Table, Alert, Typography, notification, Skeleton} from 'antd'
import RemoveConnection from 'components/remove-connection'
import {useHubspotConnectionByPleoId} from 'services/hubspot'
import {ColumnProps} from 'antd/lib/table'
import {getRegistryId, getHighestVelocity, getHubspotCompanyLink} from 'utils'
import {Company, Velocity} from 'types/kyc-overview'
import moment from 'moment'
import styled from 'styled-components'

const {Text} = Typography

const NoResults: FC<
    React.PropsWithChildren<React.PropsWithChildren<{searchedForHubspotId: boolean}>>
> = ({searchedForHubspotId}) => (
    <>
        <Alert
            type="error"
            message={
                searchedForHubspotId
                    ? 'The input corresponds to a HubSpot ID and not a Pleo ID.'
                    : 'The input did not give any results. Please make sure you typed the right format or right company name.'
            }
            showIcon
        />
        <Divider />
        <Text type="secondary">
            A Pleo company ID should have the format: XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
        </Text>
    </>
)

const columns: ColumnProps<Company>[] = [
    {
        title: 'Company ID',
        dataIndex: 'id',
        render: (companyId: string) => <Text copyable>{companyId}</Text>
    },
    {title: 'Company name', dataIndex: 'name'},
    {
        title: 'Registration no.',
        dataIndex: 'registrationNumber',
        render: (registrationNumber, company) => (
            <Text>{getRegistryId(company.address?.country, registrationNumber)}</Text>
        )
    },
    {
        title: 'KYC status',
        dataIndex: 'status',
        render: (status) => (
            <Text>{getHighestVelocity(status.kyc || status)?.toUpperCase() || Velocity.UNSET}</Text>
        )
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (createdAt) => <Text>{moment(createdAt).format('lll')}</Text>
    }
]

interface CompaniesProps {
    existentHubspotCompanyId?: string
    searchedForHubspotId: boolean
    companies?: Company[]
    company?: Company
    validating: boolean
    selected: string
    portalId: string
    searched: boolean
    isRemoving: boolean
    select: (companyId: string) => void
    setDisableConnect: (value: boolean) => void
    removeConnections: () => void
}

export const Companies: FC<React.PropsWithChildren<React.PropsWithChildren<CompaniesProps>>> = ({
    existentHubspotCompanyId,
    searchedForHubspotId,
    companies,
    company,
    selected,
    searched,
    portalId,
    validating,
    isRemoving,
    select,
    setDisableConnect,
    removeConnections
}) => {
    const alreadyConnected = !!existentHubspotCompanyId
    const noResults = !company && !companies?.length && !validating && searched
    const displayCompanies = company ? [company] : companies || []
    const hubspotCompanyLink = getHubspotCompanyLink(portalId, existentHubspotCompanyId)

    useEffect(() => {
        setDisableConnect(!selected || alreadyConnected)
    }, [setDisableConnect, selected, alreadyConnected])

    if (isRemoving) {
        return (
            <span data-testid="skeleton">
                <Skeleton />
            </span>
        )
    }
    if (noResults) {
        return <NoResults searchedForHubspotId={searchedForHubspotId} />
    }
    return (
        <>
            {(!!displayCompanies.length || validating) && (
                <>
                    <Divider />
                    <Table
                        dataSource={displayCompanies}
                        rowKey={'id'}
                        style={{paddingLeft: '35px'}}
                        columns={columns}
                        rowSelection={{
                            type: 'radio',
                            selectedRowKeys: [selected],
                            onChange: (selectedRowKeys) => {
                                select(`${selectedRowKeys?.[0]}`)
                            }
                        }}
                    />
                </>
            )}
            {alreadyConnected && (
                <>
                    <Container>
                        <Alert
                            type="warning"
                            message={`This company is already connected to HubSpot company with ID ${existentHubspotCompanyId}.`}
                            showIcon
                        />
                    </Container>
                    <Divider />
                    <Container>
                        <Text type="secondary">
                            If you wish to manage this connection, please go to the corresponding{' '}
                            <a href={hubspotCompanyLink} target="_blank" rel="noopener noreferrer">
                                HubSpot page
                            </a>{' '}
                            or remove the connection below.
                        </Text>
                    </Container>
                    <Divider />
                    <RemoveConnection
                        checkBoxMessage="I understand that removing this connection will reset the Pleo properties on the HubSpot Company information and undo the connection between Pleo and HubSpot"
                        removeConnections={removeConnections}
                    />
                </>
            )}
        </>
    )
}

interface CompanyContainerProps {
    searchedForHubspotId: boolean
    nameQuery: string
    idQuery: string
    searched: boolean
    selectedCompanyId: string
    select: (companyId: string) => void
    setDisableConnect: (value: boolean) => void
    setConnectionExists: (value: boolean) => void
}
const CompaniesContainer: FC<
    React.PropsWithChildren<React.PropsWithChildren<CompanyContainerProps>>
> = ({
    searchedForHubspotId,
    nameQuery,
    idQuery,
    searched,
    selectedCompanyId,
    select,
    setDisableConnect,
    setConnectionExists
}) => {
    const portalId = window.sessionStorage.getItem('portalId') || ''
    const [isRemoving, setIsRemoving] = useState(false)
    const {data: company, isValidating: validatingCompany} = useCompany(idQuery)

    const {data: companies, isValidating: validatingCompanies} = useCompanies(nameQuery)

    const {
        data: hubspotConnectionByCompanyId,
        isValidating: validatingConnections,
        mutations: {deleteHubspotConnection}
    } = useHubspotConnectionByPleoId(selectedCompanyId)
    const validating = validatingCompanies || validatingCompany || validatingConnections

    const existentConnectionId = hubspotConnectionByCompanyId?.id
    const existentHubspotCompanyId = hubspotConnectionByCompanyId?.hubspotCompanyId
    useEffect(() => {
        if (existentConnectionId) {
            setConnectionExists(true)
        } else {
            setConnectionExists(false)
        }
    }, [existentConnectionId, setConnectionExists])

    const removeConnections = async () => {
        try {
            setIsRemoving(true)
            await deleteHubspotConnection()
        } catch (e: any) {
            notification.error({
                message: 'Failed to remove connection',
                description: e?.message
            })
        } finally {
            setIsRemoving(false)
        }
    }

    return (
        <Companies
            existentHubspotCompanyId={existentHubspotCompanyId}
            searchedForHubspotId={searchedForHubspotId}
            companies={companies}
            company={company}
            portalId={portalId}
            selected={selectedCompanyId}
            searched={searched}
            validating={validating}
            isRemoving={isRemoving}
            select={select}
            setDisableConnect={setDisableConnect}
            removeConnections={removeConnections}
        />
    )
}

export default CompaniesContainer

const Container = styled.div`
    padding-left: 35px;
`
