import {uranusRequest} from 'services/request/handler'

export function getUranus(route: string) {
    return uranusRequest()
        .get(route)
        .then((res: Response) => res.json())
}

export function getUranusIgnoreErrors(route: string) {
    return uranusRequest()
        .get(route, {throwHttpErrors: false})
        .then((res: Response) => res.json())
}

export function deleteUranus(route: string) {
    return uranusRequest().delete(route)
}

export function postUranus(route: string, body?: Record<string, any>) {
    return uranusRequest().post(route, {
        json: body
    })
}

export function patchUranus(route: string, body?: Record<string, any>) {
    return uranusRequest().patch(route, {
        json: body
    })
}

export function putUranus(route: string, body?: Record<string, any>) {
    return uranusRequest().put(route, {
        json: body
    })
}
