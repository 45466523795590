import useSWR from 'swr'
import {HubspotConnection} from 'types/whitelist'
import {deleteUranus, getUranus, postUranus} from 'services/uranus'

function createHubspotConnection(hubspotCompanyId: string, pleoCompanyId: string) {
    return postUranus(`rest/v1/hubspot/whitelist`, {pleoCompanyId, hubspotCompanyId})
}

function destroyHubspotConnection(connectionId: string) {
    return deleteUranus(`rest/v1/hubspot/whitelist/${connectionId}`)
}

export function useHubspotConnections(companyId?: string | null) {
    const result = useSWR<HubspotConnection[]>(
        companyId ? `rest/v1/hubspot/company/${companyId}` : '',
        getUranus
    )

    const deleteHubspotConnections = async () => {
        result.data?.forEach(async (connection) => {
            await destroyHubspotConnection(connection.id)
            return result.mutate()
        })
    }

    const connectCompany = async (hubspotCompanyId: string, pleoCompanyId: string) => {
        await createHubspotConnection(hubspotCompanyId, pleoCompanyId)
        return result.mutate()
    }

    return {...result, mutations: {connectCompany, deleteHubspotConnections}}
}

export function useHubspotConnectionByPleoId(companyId?: string) {
    const result = useSWR<HubspotConnection>(
        companyId ? `rest/v1/hubspot/pleo/company/${companyId}` : '',
        getUranus
    )

    const deleteHubspotConnection = async () => {
        if (!result.data?.id) return
        await destroyHubspotConnection(result.data?.id)
        return result.mutate()
    }

    return {...result, mutations: {deleteHubspotConnection}}
}
