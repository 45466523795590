import React, {useState} from 'react'
import {Table, Button, Tooltip, DatePicker, message} from 'antd'
import styled from 'styled-components'
import {PageOrder} from '@pleo-io/deimos'
import {SortOrder, ColumnType} from 'antd/lib/table/interface'

import {
    useDemoAccounts,
    defaultPageOrder,
    defaultSortedProperty,
    defaultLimit
} from 'services/demo-accounts'
import {useUserEmail} from 'utils/use-user-email'
import {
    DemoAccountModel,
    SortableDemoAccountProperty,
    toDemoAccountsSorting
} from 'types/demo-accounts'

import moment from 'moment'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import DemoAccount from './demo-account/demo-account'
import {isPast} from 'utils/moment'

const renderFormattedTime = (timeStamp?: string) => (timeStamp ? moment().format('ll') : '-')

const sortOrderToPageOrder = (sortOrder?: SortOrder): PageOrder => {
    switch (sortOrder) {
        case 'ascend':
            return PageOrder.ASC
        case 'descend':
            return PageOrder.DESC
        default:
            return defaultPageOrder
    }
}

const fieldToSortableProperty = (field?: string): SortableDemoAccountProperty => {
    switch (field) {
        case 'createdAt':
            return 'CREATED_AT'
        case 'updatedAt':
            return 'UPDATED_AT'
        case 'expiresAt':
            return 'EXPIRES_AT'
        default:
            return defaultSortedProperty
    }
}

const ListDemoAccounts = () => {
    const [activeDemoAccountId, setActiveDemoAccountId] = useState<string | null>(null)

    const userEmail = useUserEmail()
    const {
        data: demoAccounts,
        nextPage,
        previousPage,
        updateLimit,
        pagination,
        updateSorting,
        mutations
    } = useDemoAccounts(userEmail)

    const limit = pagination?.currentRequestPagination.limit ?? defaultLimit

    const onViewCompany = (demoAccountId: string) => {
        setActiveDemoAccountId(demoAccountId)
    }

    const onGoBack = () => setActiveDemoAccountId(null)
    const onChangeLimit = (newLimit: number) => updateLimit(newLimit)

    const columns: ColumnType<DemoAccountModel>[] = [
        {
            title: 'Account name',
            dataIndex: 'name'
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            defaultSortOrder: 'descend',
            sorter: (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
            render: renderFormattedTime
        },
        {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            sorter: (a, b) => moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf(),
            render: renderFormattedTime
        },
        {
            title: 'Expires at',
            dataIndex: 'expiresAt',
            sorter: (a, b) => moment(a.expiresAt).valueOf() - moment(b.expiresAt).valueOf(),
            render: (expiresAt: string | undefined, {id}) => {
                const value = expiresAt ? moment(expiresAt) : undefined

                return (
                    <Tooltip title="Edit expiry">
                        <DatePicker
                            defaultValue={value}
                            disabledDate={isPast}
                            allowClear
                            onChange={async (date) => {
                                try {
                                    await mutations.updateExpiresAt(id, date?.toISOString() ?? null)
                                    message.success(
                                        `${date === null ? 'Removed' : 'Updated'} expires at`
                                    )
                                } catch (e: any) {
                                    message.error(e?.message)
                                }
                            }}
                        />
                    </Tooltip>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (demoAccountId: string) => (
                <Button type="link" onClick={() => onViewCompany(demoAccountId)}>
                    View details
                </Button>
            )
        }
    ]

    if (activeDemoAccountId) {
        return <DemoAccount demoAccountId={activeDemoAccountId} onGoBack={onGoBack} />
    }

    return (
        <ContentContainer>
            <Table<DemoAccountModel>
                dataSource={demoAccounts}
                rowKey="id"
                columns={columns}
                pagination={false}
                onChange={(_pagination, _filters, sorter, _extra) => {
                    // sorter is union of SorterResult<DemoAccountModel> | SorterResult<DemoAccountModel>[]
                    // we only want to handle the case where it isn't an array
                    if ('order' in sorter) {
                        updateSorting(
                            toDemoAccountsSorting(
                                fieldToSortableProperty(sorter.field as string),
                                sortOrderToPageOrder(sorter.order)
                            )
                        )
                    }
                }}
            />
            {pagination && (
                <CursorPaginationControls
                    isFetching={!demoAccounts}
                    pagination={pagination}
                    onChangeLimit={onChangeLimit}
                    limit={limit}
                    onNextPage={nextPage}
                    onPrevPage={previousPage}
                />
            )}
        </ContentContainer>
    )
}

export default ListDemoAccounts

export const ContentContainer = styled.div`
    display: grid;
    gap: 24px;
`
