import React, {FC, useState} from 'react'
import {useHubspotConnections} from 'services/hubspot'
import {useCompany} from 'services/company'
import {Company} from 'types/kyc-overview'
import {HubspotConnection} from 'types/whitelist'
import ConnectionExists from './connection-exists'
import SearchToConnectContainer from './search-to-connect'
import {SuccessfullyConnectedCompany, SomethingWentWrong} from 'components/page-message'
import {notification, Skeleton} from 'antd'

interface ConnectCompanyProps {
    hubspotId: string
    hubspotName: string
    deimosCompany?: Company
    hubspotConnection?: HubspotConnection
    isRemoving: boolean
    removeConnections: () => void
}
export const ConnectCompany: FC<
    React.PropsWithChildren<React.PropsWithChildren<ConnectCompanyProps>>
> = ({hubspotId, hubspotName, deimosCompany, hubspotConnection, isRemoving, removeConnections}) => {
    const [complete, setComplete] = useState(false)
    const [error, setError] = useState('')
    const connectionDetails = {
        pleoName: deimosCompany?.name || '',
        pleoId: deimosCompany?.id || '',
        hubspotName,
        hubspotId
    }
    if (error) {
        return <SomethingWentWrong message={error} />
    }
    if (isRemoving) {
        return (
            <span data-testid="skeleton">
                <Skeleton />
            </span>
        )
    }
    if (complete) {
        return <SuccessfullyConnectedCompany />
    }
    if (hubspotConnection) {
        return (
            <ConnectionExists
                connectionDetails={connectionDetails}
                removeConnections={removeConnections}
            />
        )
    }

    return (
        <SearchToConnectContainer
            hubspotId={hubspotId}
            setComplete={setComplete}
            setError={setError}
        />
    )
}

const ConnectCompanyContainer: FC<
    React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = () => {
    const [isRemoving, setIsRemoving] = useState(false)

    const hubspotId = window.sessionStorage.getItem('company') || ''
    const hubspotName = window.sessionStorage.getItem('name') || ''

    const {
        data: hubspotConnections,
        mutations: {deleteHubspotConnections}
    } = useHubspotConnections(hubspotId)

    const hubspotConnection = hubspotConnections?.find((connection) => connection.companyId)

    const deimosCompanyId = hubspotConnection?.companyId
    const {data: deimosCompany} = useCompany(deimosCompanyId)

    const onRemoveConnections = async () => {
        try {
            setIsRemoving(true)
            await deleteHubspotConnections()
        } catch (e: any) {
            notification.error({
                message: 'Failed to remove connection',
                description: e?.message
            })
        } finally {
            setIsRemoving(false)
        }
    }

    return (
        <ConnectCompany
            deimosCompany={deimosCompany}
            hubspotId={hubspotId}
            hubspotName={hubspotName}
            hubspotConnection={hubspotConnection}
            isRemoving={isRemoving}
            removeConnections={onRemoveConnections}
        />
    )
}

export default ConnectCompanyContainer
