import React from 'react'

class IgnoreErrorBoundary extends React.Component<
    {fallbackMessage?: string; children: React.ReactNode},
    {hasError: boolean; error: any}
> {
    constructor(props: any) {
        super(props)
        this.state = {
            hasError: false,
            error: null
        }
    }

    static getDerivedStateFromError = () => {
        return {hasError: true}
    }

    componentDidCatch = (error: any) => {
        this.setState({error})
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallbackMessage
        }
        return this.props.children
    }
}

export default IgnoreErrorBoundary
