import React, {ReactElement} from 'react'
import {Divider, Button, Tooltip} from 'antd'
import styled from 'styled-components'
import {Formik, useFormikContext} from 'formik'
import {Input, Form, FormItem, InputNumber, DatePicker} from 'formik-antd'
import * as Yup from 'yup'
import moment from 'moment'

import SupportedCountrySelect from 'components/supported-countries-select'
import {supportedCountries, SupportedCountry} from 'types/countries'
import {
    CreateDemoAccountRequest,
    CreateDemoAccountType,
    GetPrecreatedDemoAccountRequest
} from 'types/demo-accounts'
import SupportedLanguageSelect from 'components/supported-language-select'
import {SupportedLanguage} from 'types/supported-language'
import {useCompanyName} from 'utils/use-company-name'
import {isPast} from 'utils/moment'

const validationSchemaBasic = Yup.object()
    .shape({
        companyName: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        expiresAt: Yup.string().nullable().notRequired()
    })
    .required()

const validationSchemaExtended = Yup.object()
    .shape({
        companyName: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        numberOfEmployees: Yup.number().required('Required'),
        walletBalance: Yup.number().required('Required'),
        numberOfExpenses: Yup.number().required('Required'),
        language: Yup.string().required('Required'),
        expiresAt: Yup.string().nullable().notRequired()
    })
    .required()

export type FormValuesExtended = Yup.InferType<typeof validationSchemaExtended> & {
    country: SupportedCountry
    language: SupportedLanguage
}

export type FormValuesBasic = Yup.InferType<typeof validationSchemaBasic> & {
    country: SupportedCountry
}

export const formValuesToCreateDemoAccountRequest = (
    formValues: FormValuesExtended,
    requesterEmail?: string
): CreateDemoAccountRequest => ({
    companyName: formValues.companyName,
    country: formValues.country,
    expenses: {
        randomExpenseCount: formValues.numberOfExpenses,
        expensePreset: {
            country: formValues.country
        }
    },
    initialWalletLoadAmount: formValues.walletBalance,
    memberEmployeesCount: formValues.numberOfEmployees,
    language: formValues.language,
    requesterEmail,
    expiresAt: formValues.expiresAt
})

export const formValuesToGetPreCreatedDemoAccountRequest = (
    formValues: FormValuesBasic,
    requesterEmail?: string
): GetPrecreatedDemoAccountRequest => ({
    companyName: formValues.companyName,
    country: formValues.country,
    requesterEmail
})

interface Props {
    createDemoAccountType: CreateDemoAccountType
    onSubmitExtended: (values: FormValuesExtended) => void
    onSubmitBasic: (values: FormValuesBasic) => void
}

const ExpiresAtTooltip = ({children}: {children: ReactElement}) => {
    const {values} = useFormikContext<FormValuesExtended>()

    if (!values.expiresAt) {
        return children
    }

    return (
        <Tooltip
            color="red"
            placement="right"
            title={`This account will expire ${moment(values.expiresAt).fromNow()}`}
        >
            {children}
        </Tooltip>
    )
}

const GenerateDemoForm = ({createDemoAccountType, onSubmitExtended, onSubmitBasic}: Props) => {
    const companyName = useCompanyName()
    const twoWeekFromNow = moment().add(2, 'week').toISOString()

    if (createDemoAccountType === CreateDemoAccountType.EXTENDED) {
        return (
            <Formik
                validationSchema={validationSchemaExtended}
                onSubmit={onSubmitExtended}
                initialValues={{
                    companyName,
                    country: supportedCountries[0],
                    numberOfEmployees: 5,
                    walletBalance: 1000,
                    numberOfExpenses: 5,
                    language: SupportedLanguage.EN,
                    expiresAt: twoWeekFromNow
                }}
            >
                <Form layout="vertical">
                    <FormLayout>
                        <FormItemLong name="companyName" label="Company name">
                            <Input name="companyName" placeholder="Company name" />
                        </FormItemLong>
                        <FormItem name="country" label="Country">
                            <SupportedCountrySelect name="country" placeholder="Country" />
                        </FormItem>
                        <FormItem name="numberOfEmployees" label="Number of employees">
                            <InputNumber name="numberOfEmployees" min={0} max={10} precision={0} />
                        </FormItem>
                        <FormItem name="walletBalance" label="Wallet balance">
                            <InputNumber
                                name="walletBalance"
                                min={0}
                                max={50000}
                                step="0.01"
                                precision={2}
                            />
                        </FormItem>
                        <FormItem name="numberOfExpenses" label="Number of expenses">
                            <InputNumber name="numberOfExpenses" min={0} max={20} precision={0} />
                        </FormItem>
                        <FormItem name="language" label="Language">
                            <SupportedLanguageSelect name="language" />
                        </FormItem>
                        <FormItem name="expiresAt" label="Expires at">
                            <DatePicker name="expiresAt" disabledDate={isPast} allowClear />
                        </FormItem>
                    </FormLayout>
                    <Divider />
                    <ExpiresAtTooltip>
                        <Button type="primary" htmlType="submit">
                            Publish
                        </Button>
                    </ExpiresAtTooltip>
                </Form>
            </Formik>
        )
    } else {
        return (
            <Formik
                validationSchema={validationSchemaBasic}
                onSubmit={onSubmitBasic}
                initialValues={{
                    companyName,
                    country: supportedCountries[0],
                    expiresAt: twoWeekFromNow
                }}
            >
                <Form layout="vertical">
                    <FormLayout>
                        <FormItemLong name="companyName" label="Company name">
                            <Input name="companyName" placeholder="Company name" />
                        </FormItemLong>
                        <FormItem name="country" label="Country">
                            <SupportedCountrySelect name="country" placeholder="Country" />
                        </FormItem>
                    </FormLayout>
                    <Divider />
                    <ExpiresAtTooltip>
                        <Button type="primary" htmlType="submit">
                            Publish
                        </Button>
                    </ExpiresAtTooltip>
                </Form>
            </Formik>
        )
    }
}

const FormLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    & .ant-input-number {
        width: 100%;
    }
`

const FormItemLong = styled(FormItem)`
    grid-column-start: span 2;
`

export default GenerateDemoForm
