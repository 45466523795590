import {
    DetailedAddress,
    PostalAddress,
    EkycCheck,
    IdvCheck,
    KycCheck,
    Status,
    SmallCaseVelocity,
    VelocityPriority,
    PersonNameModel,
    CompanyStakeholderResponse
} from 'types/kyc-overview'
import moment from 'moment'

export const getPersonName = (name?: PersonNameModel) => {
    if (!name) return ''
    const names = [name.givenName || '', name.familyName || '']
    if (name.middleName) {
        names.splice(1, 0, name.middleName)
    }
    return names.reduce((fullName, currentName) => `${fullName} ${currentName}`)
}

export const getHighestVelocity = (status: Status): SmallCaseVelocity | undefined => {
    let highestVelocity: SmallCaseVelocity | undefined = undefined
    Object.entries(status).forEach(([velocity, value]) => {
        if (
            value &&
            (!highestVelocity ||
                VelocityPriority[velocity as SmallCaseVelocity] > VelocityPriority[highestVelocity])
        ) {
            highestVelocity = velocity as SmallCaseVelocity
        }
    })
    return highestVelocity
}

export const getRegistryId = (country: string, registrationNo: string) =>
    `${country}-${registrationNo}`

// Default argument for switch (status) so typescript notifies if we fail to cover a case
export function exhaustiveCheck(_param: never) {
    return null
}

export const validateDateOfBirth = (person: CompanyStakeholderResponse): boolean =>
    !!person.dateOfBirth &&
    !!person.dateOfBirth.day &&
    !!person.dateOfBirth.month &&
    !!person.dateOfBirth.year

export const validateAddress = (entity: any, postal = false): boolean =>
    entity && entity.address && postal
        ? validatePostalAddress(entity.address)
        : validateDetailedAddress(entity.address)

export const validateDetailedAddress = (address: DetailedAddress): boolean =>
    !!address &&
    !!(address.country || '').trim() &&
    !!(address.locality || '').trim() &&
    !!(address.postalCode || '').trim() &&
    !!(address.houseNumber || '').trim()

export const validatePostalAddress = (address: PostalAddress): boolean =>
    !!address &&
    !!(address.country || '').trim() &&
    !!(address.locality || '').trim() &&
    !!(address.postalCode || '').trim() &&
    !!(address.addressLine1 || '').trim()

export const sortByCreatedDate = (a: KycCheck, b: KycCheck) =>
    moment(a.created).valueOf() - moment(b.created).valueOf()

export const sortByUpdatedDate = (a: KycCheck, b: KycCheck) =>
    moment(a.updated).valueOf() - moment(b.updated).valueOf()

export const getLatestCreatedItem = (collection: KycCheck[]): KycCheck =>
    (collection || []).sort(sortByCreatedDate).slice(-1)[0]

export const getLatestUpdatedItem = (collection: KycCheck[]): KycCheck =>
    (collection || []).sort(sortByUpdatedDate).slice(-1)[0]

export const getLatestEkycCheck = (person: CompanyStakeholderResponse): EkycCheck =>
    getLatestCreatedItem(person.ekycChecks ?? [])

export const getLatestIdvCheck = (person: CompanyStakeholderResponse): IdvCheck =>
    getLatestUpdatedItem(person.idvChecks ?? [])

export const getHubspotCompanyLink = (portalId: string, hubsppotCompanyId?: string) =>
    `https://app.hubspot.com/contacts/${portalId}/company/${hubsppotCompanyId}/`

export function renderCompanyRegNumber(country: string): string {
    let res = 'Reg. No.'
    switch (country) {
        case 'DK':
            res = 'CVR'
            break
        case 'GB':
            res = 'CRN'
            break
    }
    return res
}
