import React, {FC} from 'react'
import {Alert, Typography} from 'antd'
import {
    CompanyStakeholderResponse,
    Document,
    KycInformation,
    KycInformationName,
    KycInformationType,
    SubjectType
} from 'types/kyc-overview'
import styled from 'styled-components'
import {getPersonName} from 'utils'

const {Text} = Typography

const excludedInformation = [KycInformationName.PERSONS_IN_SCOPE, KycInformationName.STRUCTURE]

const MissingInformation: FC<
    React.PropsWithChildren<
        React.PropsWithChildren<{
            missingInformation?: KycInformation[]
            companyDocuments: Document[]
            managers: CompanyStakeholderResponse[]
        }>
    >
> = ({missingInformation, companyDocuments, managers}) => {
    const noMissingInformation =
        !missingInformation ||
        missingInformation.filter((i) => !excludedInformation.includes(i.name)).length === 0
    if (noMissingInformation) {
        return (
            <NoMissingInformationContainer>
                <Text>No missing KYC information.</Text>
            </NoMissingInformationContainer>
        )
    }

    const companyDocumentsById: {[id: string]: Document} = {}
    companyDocuments.forEach((doc) => {
        companyDocumentsById[doc.id] = doc
    })

    const managersById: {[id: string]: CompanyStakeholderResponse} = {}
    managers.forEach((manager) => {
        managersById[manager.id] = manager
    })
    const managersIds = Object.keys(managersById)

    const isManagerMissingInfo = (missingInfo: KycInformation) =>
        missingInfo.subject === SubjectType.PERSON && managersIds.includes(missingInfo.subjectId)

    const isCompanyMissingDocument = (missingInfo: KycInformation) =>
        missingInfo.type === KycInformationType.DOCUMENT &&
        missingInfo.subject === SubjectType.COMPANY

    const isCompanyMissingExplanation = (missingInfo: KycInformation) =>
        missingInfo.subject === SubjectType.COMPANY &&
        missingInfo.name === KycInformationName.BUSINESS_EXPLANATION

    const getMessageByManager = (missingInfo: KycInformation) => {
        const manager = managersById[missingInfo.subjectId]
        const document = manager?.documents?.find((doc) => doc.id === missingInfo.documentId)
        const documentTitle =
            missingInfo.name !== KycInformationName.OTHER_DOCUMENT
                ? missingInfo.name
                : document?.title
        const details = `${missingInfo.details ?? ''} ${document?.description ?? ''}`.trim()

        return `[${documentTitle}] for ${getPersonName(manager.name)}.${
            details ? ` Details: ${details}` : ''
        }`
    }

    const getMessageForDocument = (document: Document) =>
        `[${document.category}]${document.title ? ` ${document.title}` : ''}. ${
            document.description ?? ''
        }`

    const getMessageForMissingInfo = (missingInfo: KycInformation) => {
        if (isManagerMissingInfo(missingInfo)) {
            return getMessageByManager(missingInfo)
        }
        if (isCompanyMissingDocument(missingInfo)) {
            return getMessageForDocument(
                companyDocumentsById[missingInfo.documentId || ''] || {
                    category: missingInfo.name
                }
            )
        }
        if (isCompanyMissingExplanation(missingInfo)) {
            return `[${missingInfo.name}]`
        }
    }

    return (
        <MissingInformationContainer>
            {missingInformation?.map((missingInfo, index) => {
                const messageForMissingInformation = getMessageForMissingInfo(missingInfo)
                if (!messageForMissingInformation) return null
                const message = `Missing ${missingInfo.subject.toLowerCase()} ${missingInfo.type.toLowerCase()} ${messageForMissingInformation}`
                return (
                    <Alert
                        showIcon
                        type="warning"
                        style={{
                            backgroundColor: 'transparent',
                            borderStyle: 'none',
                            paddingBottom: 0
                        }}
                        key={index}
                        message={message}
                    />
                )
            })}
        </MissingInformationContainer>
    )
}

export default MissingInformation

const MissingInformationContainer = styled.div`
    padding: 20px;
`

const NoMissingInformationContainer = styled.div`
    padding: 35px;
`
