import {parse} from 'qs'
import {useLocation} from 'react-router-dom'

interface Params {
    companyName?: string
}

export const useCompanyName = () => {
    const location = useLocation()
    const {companyName}: Params = parse(location.search, {ignoreQueryPrefix: true})

    return companyName ?? ''
}
