import {apiGatewayRequest} from 'services/request/handler'

export function getDeimos(route: string) {
    return apiGatewayRequest()
        .get(route)
        .then((res: Response) => res.json())
}

export function getDeimosIgnoreErrors(route: string) {
    return apiGatewayRequest()
        .get(route, {throwHttpErrors: false})
        .then((res: Response) => res.json())
}

export function deleteDeimos(route: string) {
    return apiGatewayRequest().delete(route)
}

export function postDeimos(route: string, body?: Record<string, any>) {
    return apiGatewayRequest().post(route, {
        json: body
    })
}

export function patchDeimos(route: string, body?: Record<string, any>) {
    return apiGatewayRequest().patch(route, {
        json: body
    })
}

export function putDeimos(route: string, body?: Record<string, any>) {
    return apiGatewayRequest().put(route, {
        json: body
    })
}
