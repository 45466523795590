import React, {FC} from 'react'
import styled from 'styled-components'
import {Result} from 'antd'

export const SuccessfullyConnectedCompany: FC<
    React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = () => (
    <Page>
        <Container>
            <Result status="success" title="Successfully connected" />
        </Container>
    </Page>
)

export const SuccessfullyInvitedPartner: FC<
    React.PropsWithChildren<React.PropsWithChildren<{email: string}>>
> = ({email}) => (
    <Page>
        <Container>
            <Result
                status="success"
                title="Partner invitation sent"
                subTitle={`An email has been sent to ${email}`}
            />
        </Container>
    </Page>
)

export const NoStyxId: FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <Page>
        <Container>
            <Title>No STYX ID</Title>
            <Content>
                We could not retrieve the company information from the registry for KYC verification
            </Content>
            <Content>Please contact compliance to solve this</Content>
        </Container>
    </Page>
)

export const InvalidToken: FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <Page>
        <Container>
            <Title>Invalid token</Title>
            <Content>
                There is something wrong with your authentication token. Please try refreshing the
                page.
            </Content>
        </Container>
    </Page>
)

export const SomethingWentWrong: FC<
    React.PropsWithChildren<
        React.PropsWithChildren<{
            message?: string
            description?: string
        }>
    >
> = ({message = '', description}) => (
    <Page>
        <Container>
            <Title>Something went wrong: {message}</Title>
            {description && <Title>{description}</Title>}
            <Content>
                <p>Please close the window and try again.</p>
                <p>If you keep seeing this, read <a href="https://www.notion.so/pleo/BackOffice-Permissions-6a1786ee282e499192994bf1337435f0?pvs=4#13153cae7a668082bca9e93cf0e52ac4" target="_blank" rel="noreferrer">this page</a>. You need certain Backoffice permissions.</p>
            </Content>
        </Container>
    </Page>
)

export const PageNotFound: FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <Page>
        <Container>
            <Title>Page Not Found</Title>
            <Content>The URL points to a non-existent page. Please try again.</Content>
        </Container>
    </Page>
)

export const Page = styled.div`
    display: flex;
    justify-content: center;
    height: 500px;
`

const Container = styled.div`
    margin: auto;
    width: fit-content;
`

const Title = styled.h3`
    margin: auto;
    text-align: center;
    font-weight: bold;
`

const Content = styled.div`
    margin: auto;
    text-align: center;
`
