import React, {FC, useState, Suspense} from 'react'
import {Input, Divider, Button, Skeleton, Typography} from 'antd'
import styled from 'styled-components'
import {ButtonContainer} from 'components/button-container'
import {useHubspotConnections} from 'services/hubspot'
import {useDebounce} from 'utils/debounce'
import CompaniesContainer from './companies'

const {Text} = Typography
const {Search} = Input

const pleoIdPattern = /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/
const hubspotIdPattern = /^\d{9,}$/

interface SearchToConnectProps {
    selectedCompanyId: string
    searchedForHubspotId: boolean
    nameQuery: string
    idQuery: string
    isConnecting: boolean
    setSearchedForHubspotId: (value: boolean) => void
    setNameQuery: (companyId: string) => void
    setIdQuery: (companyId: string) => void
    select: (companyId: string) => void
    connect: () => void
}

export const SearchToConnect: FC<
    React.PropsWithChildren<React.PropsWithChildren<SearchToConnectProps>>
> = ({
    selectedCompanyId,
    searchedForHubspotId,
    nameQuery,
    idQuery,
    isConnecting,
    setSearchedForHubspotId,
    setNameQuery,
    setIdQuery,
    select,
    connect
}) => {
    const [disableConnect, setDisableConnect] = useState(false)
    const [connectionExists, setConnectionExists] = useState(false)
    const debouncedNameQuery = useDebounce(nameQuery, 500)
    const debouncedIdQuery = useDebounce(idQuery, 500)
    const searched = !!debouncedNameQuery || !!debouncedIdQuery || searchedForHubspotId

    const search = (query: string) => {
        if (query.match(pleoIdPattern)) {
            setIdQuery(query)
            setNameQuery('')
            setSearchedForHubspotId(false)
        } else if (query.match(hubspotIdPattern)) {
            setNameQuery('')
            setIdQuery('')
            setSearchedForHubspotId(true)
        } else {
            setNameQuery(query)
            setIdQuery('')
            setSearchedForHubspotId(false)
        }
    }

    if (isConnecting) {
        return (
            <span data-testid="skeleton">
                <Skeleton />
            </span>
        )
    }

    return (
        <>
            <Text>Search for Pleo company by ID or name</Text>
            <br />
            <SearchContainer>
                <Search
                    placeholder="Search Pleo company name or ID"
                    onChange={(event) => {
                        select('')
                        search(event.target.value)
                    }}
                    style={{width: 400, alignSelf: 'center'}}
                    allowClear
                />
            </SearchContainer>
            <Suspense fallback={<Skeleton />}>
                <CompaniesContainer
                    searchedForHubspotId={searchedForHubspotId}
                    nameQuery={debouncedNameQuery}
                    idQuery={debouncedIdQuery}
                    searched={searched}
                    selectedCompanyId={selectedCompanyId}
                    select={select}
                    setDisableConnect={setDisableConnect}
                    setConnectionExists={setConnectionExists}
                />
            </Suspense>
            {!connectionExists && (
                <>
                    <Divider />
                    <ButtonContainer>
                        <Button
                            type="primary"
                            disabled={disableConnect || isConnecting}
                            onClick={connect}
                        >
                            Connect
                        </Button>
                    </ButtonContainer>
                </>
            )}
        </>
    )
}

const SearchToConnectContainer: FC<
    React.PropsWithChildren<
        React.PropsWithChildren<{
            hubspotId: string
            setComplete: (value: boolean) => void
            setError: (value: string) => void
        }>
    >
> = ({hubspotId, setComplete, setError}) => {
    const [selectedCompanyId, setSelectedCompanyId] = useState('')
    const [searchedForHubspotId, setSearchedForHubspotId] = useState(false)
    const [nameQuery, setNameQuery] = useState('')
    const [idQuery, setIdQuery] = useState('')
    const [isConnecting, setIsConnecting] = useState(false)

    const {
        mutations: {connectCompany}
    } = useHubspotConnections(hubspotId)

    const select = (companyId: string) => setSelectedCompanyId(companyId)

    const connect = async () => {
        if (!hubspotId || !selectedCompanyId) {
            return
        }
        try {
            setIsConnecting(true)
            await connectCompany(hubspotId, selectedCompanyId)
            setIsConnecting(false)
            setComplete(true)
        } catch (e: any) {
            setError(e?.message)
        }
    }

    return (
        <SearchToConnect
            selectedCompanyId={selectedCompanyId}
            searchedForHubspotId={searchedForHubspotId}
            nameQuery={nameQuery}
            idQuery={idQuery}
            isConnecting={isConnecting}
            setSearchedForHubspotId={setSearchedForHubspotId}
            setNameQuery={setNameQuery}
            setIdQuery={setIdQuery}
            select={select}
            connect={connect}
        />
    )
}

export default SearchToConnectContainer

const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 70px;
`
