import React, {FC, useState} from 'react'
import {
    Button,
    Checkbox,
    Descriptions,
    Input,
    Modal,
    notification,
    Result,
    Skeleton,
    Tooltip,
    TreeSelect
} from 'antd'
import {Form, Select} from 'antd'
import styled from 'styled-components'
import {
    deleteOffboarding,
    initiateOrUpdateOffboarding,
    useOffboardingStatus
} from 'services/company'
import {useParams} from 'react-router-dom'
import {
    CompanyOffboardingCategory,
    CompanyOffboardingReason,
    InitiateOffboardingRequest
} from '@pleo-io/deimos'
import {TreeNode} from 'antd/lib/tree-select'
import {DisplayOffboardingReason} from './utils'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Store} from 'antd/lib/form/interface'

const {confirm} = Modal
const {Option} = Select

const companyOffboardingReasonCategory = [
    {
        title: 'Bad Timing',
        options: [
            CompanyOffboardingReason.BAD_TIMING_ONE_MONTH,
            CompanyOffboardingReason.BAD_TIMING_THREE_MONTH,
            CompanyOffboardingReason.BAD_TIMING_SIX_MONTH,
            CompanyOffboardingReason.BAD_TIMING_ONE_YEAR,
            CompanyOffboardingReason.BAD_TIMING_UNKNOWN,
            CompanyOffboardingReason.BAD_TIMING_OTHER
        ]
    },
    {
        title: 'Complicated Setup',
        options: [
            CompanyOffboardingReason.COMPLICATED_SETUP_WALLET_LOAD,
            CompanyOffboardingReason.COMPLICATED_SETUP_CARD_ACTIVATION,
            CompanyOffboardingReason.COMPLICATED_SETUP_ACCOUNTING_INTEGRATION,
            CompanyOffboardingReason.COMPLICATED_SETUP_PERSONAL_VERIFICATION,
            CompanyOffboardingReason.COMPLICATED_SETUP_COMPANY_VERIFICATION
        ]
    },
    {
        title: 'Missing Feature',
        options: [
            CompanyOffboardingReason.MISSING_FEATURE_ACCOUNTING_INTEGRATION,
            CompanyOffboardingReason.MISSING_FEATURE_CREDIT,
            CompanyOffboardingReason.MISSING_FEATURE_MULTI_CURRENCY,
            CompanyOffboardingReason.MISSING_FEATURE_OPEN_API,
            CompanyOffboardingReason.MISSING_FEATURE_REPRESENTATION,
            CompanyOffboardingReason.MISSING_FEATURE_SPLIT_RECEIPT,
            CompanyOffboardingReason.MISSING_FEATURE_TEAM_HIERARCHY,
            CompanyOffboardingReason.MISSING_FEATURE_AUTO_VAT,
            CompanyOffboardingReason.MISSING_FEATURE_PDF_RECEIPTS,
            CompanyOffboardingReason.MISSING_FEATURE_EMAIL_RECEIPTS,
            CompanyOffboardingReason.MISSING_FEATURE_PROJECT_MANAGEMENT,
            CompanyOffboardingReason.MISSING_FEATURE_BILLABLE_EXPENSES,
            CompanyOffboardingReason.MISSING_FEATURE_INVOICE_MANAGEMENT,
            CompanyOffboardingReason.MISSING_FEATURE_LOUNGE_PASS,
            CompanyOffboardingReason.MISSING_FEATURE_MULTI_LOGIN,
            CompanyOffboardingReason.MISSING_FEATURE_TEAM_LIMITS,
            CompanyOffboardingReason.MISSING_FEATURE_OTHER
        ]
    },
    {
        title: 'Misunderstood Product',
        options: [
            CompanyOffboardingReason.MISUNDERSTOOD_PRODUCT_BANK_ACCOUNT,
            CompanyOffboardingReason.MISUNDERSTOOD_PRODUCT_OTHER
        ]
    },
    {
        title: 'Moved to Competitor',
        options: [
            CompanyOffboardingReason.MOVED_TO_COMPETITOR,
            CompanyOffboardingReason.MOVED_TO_COMPETITOR_SPENDESK,
            CompanyOffboardingReason.MOVED_TO_COMPETITOR_SOLDO,
            CompanyOffboardingReason.MOVED_TO_COMPETITOR_MOSS,
            CompanyOffboardingReason.MOVED_TO_COMPETITOR_RAMP,
            CompanyOffboardingReason.MOVED_TO_COMPETITOR_OTHER
        ]
    },
    {
        title: 'Organization Changes',
        options: [
            CompanyOffboardingReason.ORGANIZATION_CLOSING_COMPANY,
            CompanyOffboardingReason.ORGANIZATION_MERGING_ENTITY,
            CompanyOffboardingReason.ORGANIZATION_OTHER,
            CompanyOffboardingReason.ORGANIZATION_PLEO_CHAMPION_LEFT
        ]
    },
    {
        title: 'Pricing',
        options: [
            CompanyOffboardingReason.PRICING_LACK_FUNCTIONALITY,
            CompanyOffboardingReason.PRICING_STRUCTURE,
            CompanyOffboardingReason.PRICING_TOO_EXPENSIVE,
            CompanyOffboardingReason.PRICING_OTHER
        ]
    },
    {
        title: 'Other',
        options: [
            CompanyOffboardingReason.OTHER,
            CompanyOffboardingReason.OTHER_INVALID_SIGN_UP_FAKE,
            CompanyOffboardingReason.OTHER_INVALID_SIGN_UP_DUPLICATED,
            CompanyOffboardingReason.OTHER_LOW_EXPENSE_VOLUME,
            CompanyOffboardingReason.OTHER_FEEDBACK_REFUSED,
            CompanyOffboardingReason.OTHER_FEEDBACK_UNANSWERED
        ]
    }
]

const InitiatedOffboarding: FC<
    React.PropsWithChildren<
        React.PropsWithChildren<{initiatedOffboarding: InitiateOffboardingRequest}>
    >
> = ({initiatedOffboarding}) => (
    <Descriptions column={1} size="small">
        <Descriptions.Item label="Category"> {initiatedOffboarding.category}</Descriptions.Item>
        <Descriptions.Item label="Reasons">
            {initiatedOffboarding?.reasons
                ? initiatedOffboarding.reasons
                      .map((reason) => DisplayOffboardingReason[reason])
                      .join(', ')
                : null}
        </Descriptions.Item>
        <Descriptions.Item label="Notes"> {initiatedOffboarding.notes}</Descriptions.Item>
    </Descriptions>
)

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
    style: {maxWidth: '480px', margin: '60px auto'}
}

const OffboardingForm: FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const {id: companyId} = useParams<{id: string}>()
    const [confirmed, setConfirmed] = useState(false)
    const [initiatedOffboarding, setInitiatedOffboarding] =
        useState<InitiateOffboardingRequest | null>(null)
    const [cancelledOffboarding, setCancelledOffboarding] = useState<boolean>(false)
    const {data: offboardingStatus, isValidating} = useOffboardingStatus(companyId || '')

    const onOffboarding = async (values: Store) => {
        if (!companyId) {
            return
        }
        const request: InitiateOffboardingRequest = {
            category: values.category,
            reasons: values.reasons ?? [],
            notes: values.notes ?? ''
        }

        try {
            if (offboardingStatus) {
                await initiateOrUpdateOffboarding(companyId, request)
                notification.info({message: 'Successfully updated offboarding'})
            } else {
                await initiateOrUpdateOffboarding(companyId, request)
                notification.info({message: 'Successfully initiated offboarding'})
            }
            setInitiatedOffboarding(request)
        } catch (e: any) {
            if (offboardingStatus) {
                notification.error({message: 'Failed to update offboarding'})
            } else {
                notification.error({message: 'Failed to initiate offboarding'})
            }
        }
    }

    const cancelOffboarding = async () => {
        if (!companyId) {
            return
        }

        try {
            await deleteOffboarding(companyId)
            notification.info({message: 'Successfully cancelled offboarding'})
            setCancelledOffboarding(!cancelledOffboarding)
        } catch (e: any) {
            notification.info({message: 'Failed to cancel offboarding'})
        }
    }

    const onCancel = () => {
        confirm({
            centered: true,
            icon: <ExclamationCircleOutlined />,
            title: 'Are you sure?',
            content: 'This will cancel offboarding',
            okButtonProps: {id: 'confirm-offboarding'},
            onOk: async () => await cancelOffboarding()
        })
    }

    // This function is required to stop the TreeSelect dropdown from scrolling with the background page
    function getPopupContainer(treeSelectNode: HTMLElement): any {
        return treeSelectNode.parentNode
    }

    if (isValidating) {
        return (
            <ContentSkeleton data-testid="skeleton">
                <Skeleton />
            </ContentSkeleton>
        )
    }

    if (initiatedOffboarding) {
        return (
            <Result
                status="success"
                title="Offboarding process successfully initiated"
                subTitle="From here on CX will handle the closing of the company."
                extra={[
                    <InitiatedOffboarding initiatedOffboarding={initiatedOffboarding} key={0} />
                ]}
            />
        )
    }

    if (cancelledOffboarding) {
        return <Result status="success" title="Offboarding process successfully cancelled" />
    }

    return (
        <Form onFinish={onOffboarding} {...layout}>
            <Form.Item
                name="category"
                label="Category"
                colon={false}
                initialValue={
                    offboardingStatus
                        ? offboardingStatus.category
                        : CompanyOffboardingCategory.ACTIVE
                }
                rules={[
                    {
                        required: true,
                        message: 'Please select the category'
                    }
                ]}
            >
                <Select disabled={!!offboardingStatus}>
                    {CompanyOffboardingCategory && Object.values(CompanyOffboardingCategory)
                        ? Object.values(CompanyOffboardingCategory).map((category) =>
                              category === CompanyOffboardingCategory.LEGAL ? null : (
                                  <Option key={category} value={category}>
                                      {category}
                                  </Option>
                              )
                          )
                        : null}
                </Select>
            </Form.Item>
            <Form.Item
                name="reasons"
                label="Reasons"
                colon={false}
                initialValue={offboardingStatus?.reasons}
            >
                <TreeSelect
                    multiple
                    placeholder="Reasons"
                    getPopupContainer={(event) => getPopupContainer(event)}
                >
                    {companyOffboardingReasonCategory
                        ? companyOffboardingReasonCategory.map((reason) => (
                              <TreeNode
                                  value={reason.title}
                                  title={reason.title}
                                  key={reason.title}
                                  selectable={false}
                              >
                                  {reason?.options
                                      ? reason.options.map((option: CompanyOffboardingReason) => (
                                            <TreeNode
                                                value={option}
                                                title={DisplayOffboardingReason[option]}
                                                key={option}
                                            />
                                        ))
                                      : null}
                              </TreeNode>
                          ))
                        : null}
                </TreeSelect>
            </Form.Item>
            <Form.Item
                name="notes"
                label="Notes"
                colon={false}
                initialValue={offboardingStatus?.notes}
            >
                <Input.TextArea name="notes" data-testid="notes-input" />
            </Form.Item>
            <Form.Item name="confirm" label=" " colon={false}>
                <Checkbox onChange={() => setConfirmed(!confirmed)}>
                    I confirm that this company is offboarding
                </Checkbox>
            </Form.Item>
            <ButtonWrapper>
                <Button htmlType="submit" type="primary" disabled={!confirmed} data-testid="submit">
                    {offboardingStatus ? 'Update offboarding' : 'Initiate offboarding'}
                </Button>
                <Tooltip
                    title={offboardingStatus ? null : 'Disabled because company is not offboarding'}
                >
                    <Button
                        disabled={!offboardingStatus}
                        onClick={onCancel}
                        data-testid="cancel"
                        danger
                    >
                        Cancel offboarding
                    </Button>
                </Tooltip>
            </ButtonWrapper>
        </Form>
    )
}

export default OffboardingForm

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 8px 0;
`
const ContentSkeleton = styled.span``
