import {Form as AntdForm} from 'formik-antd'
import styled from 'styled-components'

export const Form = styled(AntdForm)`
    padding: 35px;
`
export const Container = styled.div`
    padding: 35px;
`
export const AlertContainer = styled.div`
    padding: 20px;
`
export const BoldLabel = styled.span`
    font-weight: bold;
`
export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    margin-top: 30px;
`
export const InfoColumn = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
`
export const InfoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 35px;
`
export const HubspotConnectionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 35px;
`
