import {presetPalettes, red, green, grey, orange, PalettesProps} from '@ant-design/colors'

export const theme = {
    colors: {
        danger: red[4],
        warn: orange[4],
        success: green[4],
        secondary: grey[5]
    },
    colorPalette: {
        ...presetPalettes
    } as PalettesProps
}

type Colors = keyof typeof theme.colors
export const getColor = (name: Colors) => theme.colors[name]
